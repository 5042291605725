import React from 'react'

const UnsolicitedComment = ({resData}) => {
  return (
    <div className="">
        <div style={{ width: "155px"}}>
        <p>{resData}</p>
        </div>
    </div>
  )
}

export default UnsolicitedComment