import { Button, Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import ExFactoryDate from "../Table/ExFactoryDate/ExFactoryDate";
import Request from "../Table/Request/Request";
import Brand from "../Table/Brand/Brand";
import QtyReqRec from "../Table/QtyReqRec/QtyReqRec";
import DateSentReceive from "../Table/DateSentRec/DateSentRec";
import { useDispatch, useSelector } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import {
  selectUnselectSrtTableRows,
  srtEXportArray,
  srtUnsolicitedEditData,
} from "../../../Redux/actions/Srt/Srt";
import EditIcon from "../../../images/edit-icon.png";
import RowSavedData from "../../SRF/TableComp.js/RowSavedData";
import UnsolicitedInput from "./UnsolicitedCommonComp/UnsolicitedInput.js/UnsolicitedInput";
import UnsolicitedReceivedDate from "./UnsolicitedCommonComp/UnsolicitedReceivedDate/UnsolicitedReceivedDate";
import UnsolicitedComment from "./UnsolicitedCommonComp/UnsolicitedComment/UnsolicitedComment";

const UnsolicitedTable = () => {
  const dispatch = useDispatch();
  const [editingKey, setEditingKey] = useState("");
  let srtUnsolicitedListData = useSelector(
    (state) => state?.Srt?.srtUnsolicitedList
  );
  const massFilterApiData = useSelector(
    (state) => state?.Srt?.unsolicitedMassSearchFilterResult
  );
  const seasonFilterApiData = useSelector(
    (state) => state?.Srt?.seasonSearchFilterResult
  );
  const srtSearchLoader = useSelector((state) => state?.Srt?.searchLoader);
  const srtSeasonData = useSelector((state) => state?.Srt?.srtSeasonSearch);
  const selectedUnselectedRows = useSelector(
    (state) => state?.Srt?.srtSelectedTableRows
  );

  const [uncolicitedEditValues, setUnsolicitedEditValues] = useState({
    id: "",
    factory: "",
    COO: "",
    description: "",
    reference_number: "",
    color: "",
    size: "",
    date_received: "",
    qty_prs_received: "",
    comments: "",
  });

  const pageSize = 10;
  const initialPage = localStorage.getItem("currentPage") || 1;
  const defaultCurrent = parseInt(initialPage, 10);
  const [currentPage, setCurrentPage] = useState(defaultCurrent);

  const onSelectChange = (newSelectedRowKeys, record) => {
    console.log("newSelectedRowKeysrecord", newSelectedRowKeys, record);

    const pidArray = [];
    const brandArray = record?.map((item) => {
      pidArray.push(item.ReferenceNumber.props.children);
    });
    const filteredIds = intialSearch()
      .filter((item, index) => newSelectedRowKeys.includes(index))
      .map((item) => item?.id);

    console.log("pidArray", pidArray, filteredIds);

    dispatch(srtEXportArray(pidArray));
    dispatch(selectUnselectSrtTableRows(newSelectedRowKeys));
  };

  const paginationConfig = {
    pageSize,
    defaultCurrent,
    onChange: (page) => handlePageChange(page),
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    localStorage.setItem("currentPage", page);
  };

  const editForGettingData = (record, key) => {
    console.log("record, key", record, key);

    setUnsolicitedEditValues({
      id: record?.key,
      factory: record?.Factory?.props?.children,
      COO: record?.COO?.props?.children,
      description: record?.Description?.props?.children,
      reference_number: record?.ReferenceNumber?.props?.children,
      color: record?.Color?.props?.children,
      size: record?.Size?.props?.children,
      date_received: record?.DateReceived?.props?.children,
      qty_prs_received: record?.QtyPrsRequestedReceived?.props?.children,
      comments: record?.Comments?.props?.children,
    });

    setEditingKey(key);
  };
  const cancelButton = (record, key) => {
    setEditingKey("");
  };

  const isEditing = (record) => record.key === editingKey;

  const intialSearch = () => {
    if (massFilterApiData && massFilterApiData?.length > 0) {
      return massFilterApiData;
    } else if (seasonFilterApiData && seasonFilterApiData?.length > 0) {
      return seasonFilterApiData;
    } else if (srtSeasonData && srtSeasonData?.length > 0) {
      return srtSeasonData;
    } else if (srtUnsolicitedListData) {
      return srtUnsolicitedListData;
    } else return [];
  };

  const onInputsEditsChange = (event) => {
    const { name, value } = event.target;
    if (name === "qty_prs_received") {
      if (/^\d*$/.test(value)) {
        setUnsolicitedEditValues({
          ...uncolicitedEditValues,
          [name]: String(value),
        });
      }
    } else
      setUnsolicitedEditValues({ ...uncolicitedEditValues, [name]: value });
  };

  const onReceivedDateChange = (date, dateString) => {
    console.log("date, dateString", date, dateString);
    setUnsolicitedEditValues({
      ...uncolicitedEditValues,
      date_received: dateString,
    });
  };

  const saveButton = () => {
    dispatch(srtUnsolicitedEditData(uncolicitedEditValues));
    setEditingKey("");
  };

  const columns = [
    {
      title: "Factory",
      dataIndex: "Factory",
      width: "10%",
      render: (text, record) => {
        const editable = isEditing(record);
        console.log("recordunsoliciated", record);

        if (editable) {
          return (
            <UnsolicitedInput
              value={uncolicitedEditValues?.factory}
              name="factory"
              onchange={onInputsEditsChange}
            />
          );
        } else {
          return <RowSavedData resData={record?.Factory?.props?.children} />;
        }
      },
    },
    {
      title: "COO",
      dataIndex: "COO",
      width: "10%",
      render: (text, record) => {
        const editable = isEditing(record);

        if (editable) {
          return (
            <UnsolicitedInput
              value={uncolicitedEditValues?.COO}
              name="COO"
              onchange={onInputsEditsChange}
            />
          );
        } else {
          return <RowSavedData resData={record?.COO?.props?.children} />;
        }
      },
    },
    {
      title: "Description",
      dataIndex: "Description",
      width: "10%",
      render: (text, record) => {
        const editable = isEditing(record);

        if (editable) {
          return (
            <UnsolicitedInput
              value={uncolicitedEditValues?.description}
              name="description"
              onchange={onInputsEditsChange}
            />
          );
        } else {
          return (
            <RowSavedData resData={record?.Description?.props?.children} />
          );
        }
      },
    },
    {
      title: "Reference Number",
      dataIndex: "ReferenceNumber",
      width: "10%",
      render: (text, record) => {
        const editable = isEditing(record);

        if (editable) {
          return (
            <UnsolicitedInput
              value={uncolicitedEditValues?.reference_number}
              name="reference_number"
              onchange={onInputsEditsChange}
            />
          );
        } else {
          return (
            <RowSavedData resData={record?.ReferenceNumber?.props?.children} />
          );
        }
      },
    },
    {
      title: "Color",
      dataIndex: "Color",
      width: "10%",
      render: (text, record) => {
        const editable = isEditing(record);

        if (editable) {
          return (
            <UnsolicitedInput
              value={uncolicitedEditValues?.color}
              name="color"
              onchange={onInputsEditsChange}
            />
          );
        } else {
          return <RowSavedData resData={record?.Color?.props?.children} />;
        }
      },
    },
    {
      title: "Size",
      dataIndex: "Size",
      width: "10%",
      render: (text, record) => {
        const editable = isEditing(record);

        if (editable) {
          return (
            <UnsolicitedInput
              value={uncolicitedEditValues?.size}
              name="size"
              onchange={onInputsEditsChange}
            />
          );
        } else {
          return <RowSavedData resData={record?.Size?.props?.children} />;
        }
      },
    },
    {
      title: "Date Received",
      dataIndex: "DateReceived",
      width: "10%",
      render: (text, record) => {
        const editable = isEditing(record);

        if (editable) {
          return (
            <UnsolicitedReceivedDate
              recievedDate={record?.DateReceived?.props?.children}
              name="date_received"
              onDateChange={onReceivedDateChange}
            />
          );
        } else {
          return (
            <RowSavedData resData={record?.DateReceived?.props?.children} />
          );
        }
      },
    },
    {
      title: "Qty.Prs. Received",
      dataIndex: "QtyPrsRequestedReceived",
      width: "10%",
      render: (text, record) => {
        const editable = isEditing(record);

        if (editable) {
          return (
            <UnsolicitedInput
              value={uncolicitedEditValues?.qty_prs_received}
              name="qty_prs_received"
              onchange={onInputsEditsChange}
            />
          );
        } else {
          return (
            <RowSavedData
              resData={record?.QtyPrsRequestedReceived?.props?.children}
            />
          );
        }
      },
    },
    {
      title: "Comments",
      dataIndex: "Comments",
      width: "10%",
      render: (text, record) => {
        const editable = isEditing(record);

        if (editable) {
          return (
            <UnsolicitedInput
              value={uncolicitedEditValues?.comments}
              name="comments"
              onchange={onInputsEditsChange}
            />
          );
        } else {
          return (
            <UnsolicitedComment resData={record?.Comments?.props?.children} />
          );
        }
      },
    },
    {
      title: <p className="remove-color">hi</p>,
      dataIndex: "action",
      width: "7%",
      render: (_, record, index) => {
        const editable = isEditing(record);
        console.log("editable", editable);

        if (!editable) {
          return (
            <div className="addbutton-and-edit-container">
              <img
                className="edit-row-img"
                src={EditIcon}
                onClick={() => editForGettingData(record, record.key)}
                alt="edit"
              />
            </div>
          );
        } else {
          return (
            <div>
              <Button
                className={"srf-cancel"}
                onClick={() => saveButton(record, record.key)}
              >
                Save
              </Button>
              <Button
                className={"srf-cancel"}
                onClick={() => cancelButton(record, record.key)}
              >
                Cancel
              </Button>
            </div>
          );
        }
      },
    },
  ];

  const rowSelection = {
    selectedUnselectedRows,
    onChange: onSelectChange,
  };

  const data = [];
  {
    intialSearch()?.map((item, index) => {
      return data.push({
        key: item?.id,
        Factory: <p className="vendor-name">{item?.factory_name}</p>,
        COO: <span className="socks-id">{item?.COO}</span>,
        Description: <span className="socks-color">{item?.description}</span>,
        ReferenceNumber: (
          <span className="socks-size">{item?.reference_number}</span>
        ),
        Color: <span className="socks-color">{item?.color}</span>,
        Size: <span className="socks-size">{item?.size}</span>,
        DateReceived: (
          <span className="vendor-name">{item?.date_received}</span>
        ),
        QtyPrsRequestedReceived: (
          <span className="promised-ex-factory">{item?.qty_prs_received}</span>
        ),
        Comments: (
          <p
            className="vendor-name"
            style={{ width: "350px", margin: "0 auto" }}
          >
            {item?.comments}
          </p>
        ),
      });
    });
  }
  // const srtScrollPosition = localStorage.getItem("srtAutoScroll");

  // const containerRef = useRef(null);

  // const handleScroll = () => {
  //   console.log("handlescrolldata");

  //   if (containerRef.current) {
  //     const scrollTop = containerRef?.current?.scrollTop;
  //     console.log("scrolled postion", scrollTop);
  //     localStorage.setItem("srtAutoScroll", scrollTop);
  //   }
  // };

  // const handleScrollButton = () => {
  //   if (srtScrollPosition) {
  //     window.scrollTo({
  //       top: containerRef?.current?.offsetTop,
  //     });
  //     containerRef?.current?.scrollTo({
  //       top: srtScrollPosition,
  //     });
  //   }
  // };

  // useEffect(() => {
  //   handleScrollButton();
  // }, [
  //   srtUnsolicitedListData,
  //   massFilterApiData,
  //   seasonFilterApiData,
  //   srtSeasonData,
  // ]);
  return (
    <div>
      {srtSearchLoader ? (
        <BeatLoader
          color={"#0C3D5E"}
          loading={srtSearchLoader}
          cssOverride={{
            margin: "30px auto",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            borderColor: "#0C3D5E",
          }}
          size={10}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <div className="unsolicited-table">
          {intialSearch()?.length == 0 ? (
            <Table
              onMouseEnter={false}
              rowSelection={rowSelection}
              columns={columns}
              dataSource={[]}
              pagination={{
                ...paginationConfig,
                current: currentPage,
              }}
              rowClassName={() => "row"}
            />
          ) : (
            <Table
              onMouseEnter={false}
              rowSelection={rowSelection}
              columns={columns}
              dataSource={data}
              pagination={{
                ...paginationConfig,
                current: currentPage,
              }}
              rowClassName={() => "row"}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default UnsolicitedTable;
