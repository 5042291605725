import { Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import ExFactoryDate from "../Table/ExFactoryDate/ExFactoryDate";
import Request from "../Table/Request/Request";
import Brand from "../Table/Brand/Brand";
import QtyReqRec from "../Table/QtyReqRec/QtyReqRec";
import DateSentReceive from "../Table/DateSentRec/DateSentRec";
import { useDispatch, useSelector } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import {
  selectUnselectSrtTableRows,
  srtEXportArray,
} from "../../../Redux/actions/Srt/Srt";

const SalesTable = () => {
  const dispatch = useDispatch();
  let srtDataList = useSelector((state) => state?.Srt?.getSrtDataList);
  const massFilterApiData = useSelector(
    (state) => state?.Srt?.massSearchFilterResult
  );
  const seasonFilterApiData = useSelector(
    (state) => state?.Srt?.seasonSearchFilterResult
  );
  const srtSearchLoader = useSelector((state) => state?.Srt?.searchLoader);
  const srtSeasonData = useSelector((state) => state?.Srt?.srtSeasonSearch);
  const selectedUnselectedRows = useSelector(
    (state) => state?.Srt?.srtSelectedTableRows
  );

  const pageSize = 10;
  const initialPage = localStorage.getItem("currentPage") || 1;
  const defaultCurrent = parseInt(initialPage, 10);
  const [currentPage, setCurrentPage] = useState(defaultCurrent);

  const onParentStyleClicked = () => {
    localStorage.setItem(
      "DashboardData",
      JSON.stringify({
        style: sockId,
        factory: vendor,
        season: season,
        brand: brand,
      })
    );
    dispatch(resetSrfSearchData([]));
    localStorage.setItem("RequestSend", false);
    dispatch(changingTabsWithCustomButton("true.2"));
    localStorage.removeItem("SrfSearchData");
    navigate("/sample-request-form");
  };

  const onSelectChange = (newSelectedRowKeys, record) => {
    const pidArray = [];
    const brandArray = record?.map((item) => {
      pidArray.push(item.BRAND[0].props.dataOfRow.style_pid);
    });
    console.log("record", record, pidArray);
    dispatch(srtEXportArray(pidArray));
    dispatch(selectUnselectSrtTableRows(newSelectedRowKeys));
  };

  const paginationConfig = {
    pageSize,
    defaultCurrent,
    onChange: (page) => handlePageChange(page),
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    localStorage.setItem("currentPage", page);
  };

  const intialSearch = () => {
    if (massFilterApiData && massFilterApiData?.length > 0) {
      return massFilterApiData;
    } else if (seasonFilterApiData && seasonFilterApiData?.length > 0) {
      return seasonFilterApiData;
    } else if (srtSeasonData && srtSeasonData?.length > 0) {
      return srtSeasonData;
    } else if (srtDataList) {
      return srtDataList;
    } else return [];
  };

  const columns = [
    {
      title: "Style",
      dataIndex: "Style",
    },
    {
      title: "Vendor",
      dataIndex: "Vendor",
    },
    {
      title: "Color",
      dataIndex: "Color",
    },
    {
      title: "Size",
      dataIndex: "Size",
    },
    {
      title: "Request",
      dataIndex: "Request",
    },
    {
      title: "Promised Ex-Factory",
      dataIndex: "PromiseExFactory",
    },
    {
      title: "Date Sent/ Received",
      dataIndex: "DateSentReceived",
    },
    {
      title: "Qty.Prs. Requested/Received",
      dataIndex: "QtyPrsRequestedReceived",
    },
    {
      title: "BRAND",
      dataIndex: "BRAND",
    },
  ];
  const rowSelection = {
    selectedUnselectedRows,
    onChange: onSelectChange,
  };

  const data = [];
  {
    intialSearch()?.map((item, index) => {
      return data.push({
        key: index,
        Style: (
          <div className="srt-table-data-one">
            <p className="socks-id" onClick={onParentStyleClicked}>
              {item?.style}
            </p>
            <p className="common-font">{item?.description}</p>
          </div>
        ),
        Vendor: <span className="vendor-name">{item?.factory}</span>,
        Color: <span className="socks-color">{item?.color_code}</span>,
        Size: <span className="socks-size">{item?.size}</span>,
        Request: item?.request_details?.map((data, subIndex) => {
          return <Request request={data?.request_no} />;
        }),
        PromiseExFactory: item?.request_details?.map((data, subIndex) => {
          return (
            <ExFactoryDate
              requestDate={data?.req_exfactory}
              createdDate={data.created_date}
              requestRecievedDate={data?.promised_exfactory}
              dataOfReq={data}
              itemData={item}
              salesSample="1"
            />
          );
        }),
        DateSentReceived: item?.request_details?.map((data, subIndex) => {
          return (
            <DateSentReceive
              reqNo={data?.request_no}
              createdDate={data.created_date}
              dataOfReq={data}
              recieveDate={data?.request_date}
              requestRecievedDate={data?.request_recieved}
              itemData={item}
              salesSample="1"
            />
          );
        }),
        QtyPrsRequestedReceived: item?.request_details?.map(
          (data, subIndex) => {
            return (
              <>
                <QtyReqRec
                  data={data}
                  user={data?.user}
                  promisedDate={data?.promised_exfactory}
                  requestRecievedDate={data?.request_recieved}
                  qtyReciever={data?.Qty}
                  qtyRecieved={data?.QtyRcd}
                  commentReciever={data?.quantity_cmt}
                  dataOfRow={item}
                  dataOfReq={data}
                  reqNo={data?.request_no}
                  itemData={item}
                  salesSample="1"
                />
              </>
            );
          }
        ),
        BRAND: item?.request_details?.map((data, subIndex) => {
          console.log("brandquantity", data);
          return (
            <Brand
              user={data?.user}
              data={data}
              brandStatus={data?.brand_status}
              statusFor="brand"
              dataOfRow={item}
              reqNo={data?.request_no}
              commentReciever={data?.brand_cmt}
              salesSample="1"
            />
          );
        }),
      });
    });
  }
  const srtScrollPosition = localStorage.getItem("srtAutoScroll");

  const containerRef = useRef(null);

  const handleScroll = () => {
    console.log("handlescrolldata");

    if (containerRef.current) {
      const scrollTop = containerRef?.current?.scrollTop;
      console.log("scrolled postion", scrollTop);
      localStorage.setItem("srtAutoScroll", scrollTop);
    }
  };

  const handleScrollButton = () => {
    if (srtScrollPosition) {
      window.scrollTo({
        top: containerRef?.current?.offsetTop,
      });
      containerRef?.current?.scrollTo({
        top: srtScrollPosition,
      });
    }
  };

  useEffect(() => {
    console.log(
      "scroll position of localstorage",
      srtScrollPosition,
      srtDataList
    );
    handleScrollButton();
  }, [srtDataList, massFilterApiData, seasonFilterApiData, srtSeasonData]);
  return (
    <div>
      {srtSearchLoader ? (
        <BeatLoader
          color={"#0C3D5E"}
          loading={srtSearchLoader}
          cssOverride={{
            margin: "30px auto",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            borderColor: "#0C3D5E",
          }}
          size={10}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : null}
      <div ref={containerRef} onScroll={handleScroll} className="srt-table">
        {intialSearch()?.length == 0 ? (
          <Table
            onMouseEnter={false}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={[]}
            pagination={{
              ...paginationConfig,
              current: currentPage,
            }}
            rowClassName={() => "row"}
            // onRow={(record, rowIndex) => {
            //   return {
            //     onClick: (event) => {
            //       handleRowClick(record, rowIndex);
            //     },
            //   };
            // }}
          />
        ) : (
          <Table
            onMouseEnter={false}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
            pagination={{
              ...paginationConfig,
              current: currentPage,
            }}
            rowClassName={() => "row"}
            // onRow={(record, rowIndex) => {
            //   return {
            //     onClick: (event) => {
            //       handleRowClick(record, rowIndex);
            //     },
            //   };
            // }}
          />
        )}
      </div>
      {/* // )} */}
    </div>
  );
};

export default SalesTable;
