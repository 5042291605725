import React, { useState } from "react";
import { Button, DatePicker, Modal, Tooltip } from "antd";
import dayjs from "dayjs";
import {
  ExFactoryDateVariable,
  srtDataSaver,
} from "../../../../Redux/actions/Srt/Srt";
import { useDispatch, useSelector } from "react-redux";
import "./ExFactoryDate.css";
import resetCalendar from "../../../../images/calendarReset.png"
import InputField from "../../../../UIComponents/Input/Input";
import trackEdit from "../../../../images/track-edit.png"
import { toast } from "../../../../UIComponents/Toast";
import ResetUrl from "../../../../images/closeBlue.png";

const ExFactoryDate = ({
  createdDate,
  requestDate,
  requestRecievedDate,
  itemData,
  dataOfReq,
  salesSample
}) => {
  console.log("createdDate", createdDate, itemData,
  dataOfReq,);
  console.log("requestRecievedDate",requestDate, typeof requestDate);
  const dispatch = useDispatch();
  const dateFormat = "MM/DD/YYYY";
  const isToday = dayjs().format(dateFormat);
  const [date, setDate] = useState(isToday);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [resetTrackingModalOpen, setresetTrackingModalOpen] = useState(false);
  const [isTrackModalOpen, setIsTrackModalOpen] = useState(false);
  const [trackingUrl, setTrackingUrl] = useState("")
  const brandNameFromApi = useSelector((state) => state?.Srt?.srtBrandNames);
  console.log("trackingUrl",trackingUrl);
  const onDateChange = (date, dateString) => {
    console.log("iam date", dateString);
    dispatch(
      srtDataSaver(
        itemData?.style,
        itemData?.brand,
        itemData?.factory,
        itemData?.size,
        itemData?.season,
        itemData?.color_code,
        dataOfReq?.request_no,
        "",
        dateString,
        "",
        "",
        salesSample
      )
    );

    // dispatch(ExFactoryDateVariable(dateString))
    // setDate(dateString);
  };

  const disabledDate = (current) => {
    // disable dates before today

    const currentDate = dayjs(createdDate);
    if (current < currentDate.startOf("day")) {
      return true;
    }
    // disable dates after 3 months from today
    //  const threeMonthsLater = currentDate.add(3, 'months');
    //  if (current > threeMonthsLater.endOf('day')) {
    //    return true;
    //  }
    return false;
  };

  const onResetClick = () => {
    setIsModalOpen(!isModalOpen)
  }

  const onTrackClick = () => {
    if (!requestDate ||
      requestDate == " " ||
      requestDate == "" ||
      requestDate == null || requestDate == "null") {
        setIsTrackModalOpen(false)
      }
    setIsTrackModalOpen(true)
  }

  const onTrackClickForUrl = (url) => {
    setTrackingUrl(url)
    if (!requestDate ||
      requestDate == " " ||
      requestDate == "" ||
      requestDate == null || requestDate == "null") {
        setIsTrackModalOpen(false)
      }
    setIsTrackModalOpen(true)
  }

  const onTrackUrlClick = (url) => {
    let originalUrl = url;
    if (originalUrl.includes('~')) {
      originalUrl = originalUrl.replace(/\~/g, '&');
    }
    window.open(originalUrl, '_blank');
}

  const onTrackUrlChange = (e) => {
    let newValue = e.target.value;
    if (newValue.includes('&')) {
      newValue = newValue.replace(/&/g, '~');
      setTrackingUrl(newValue);
    } else {
      setTrackingUrl(newValue);
    }
  }

  const onTrackSave = (url) => {
    if (trackingUrl === url) {
      toast.error("This Url already Exists")
    }
    else if (trackingUrl && trackingUrl !== url) {
      dispatch(
        srtDataSaver(
          itemData?.style,
          itemData?.brand,
          itemData?.factory,
          itemData?.size,
          itemData?.season,
          itemData?.color_code,
          dataOfReq?.request_no,
          trackingUrl,
          "",
          "",
          "",
          salesSample
        )
      );
      setIsTrackModalOpen(false)
    }
    else {
      toast.error("Please Enter the tracking Url")
    }
  }
  console.log("trackingUrl",trackingUrl);
  const handleTrackCancel = () => {
    setIsTrackModalOpen(false)
  }

  const onResetUrl = () => {
    setresetTrackingModalOpen(true)
  }

  const onResetUrlSave = () => {
    dispatch(
      srtDataSaver(
        itemData?.style,
        itemData?.brand,
        itemData?.factory,
        itemData?.size,
        itemData?.season,
        itemData?.color_code,
        dataOfReq?.request_no,
        null,
        "",
        "",
        "",
        salesSample
      )
    );
  }

  const onContinue = () => {
    dispatch(
      srtDataSaver(
        itemData?.style,
        itemData?.brand,
        itemData?.factory,
        itemData?.size,
        itemData?.season,
        itemData?.color_code,
        dataOfReq?.request_no,
        "",
        null,
        "",
        "",
        salesSample
      )
    );
    setIsModalOpen(!isModalOpen)
  }
  const handleCancel = () => {
    setIsModalOpen(!isModalOpen)
  }
  
  const onResetHandleCancel = () => {
    setresetTrackingModalOpen(false)
  }

  const dateObj = dayjs(requestRecievedDate);
  const formattedDate = dateObj.format("MM/DD/YYYY");
  return (
    <div className="srt-table-data-three">
      <div className="srt-date-pickers">
      <p>{requestDate}</p>
      {!requestDate ||
      requestDate == " " ||
      requestDate == "" ||
      requestDate == null || requestDate == "null" && !dataOfReq?.tracking_link  ? 
        <div>
          <DatePicker
            allowClear={false}
            onChange={onDateChange}
            disabledDate={disabledDate}
            disabled={
              !requestDate ||
              requestDate == " " ||
              requestDate == "" ||
              requestDate == null || requestDate == "null"
            }
            format="MM/DD/YYYY"
          />
          <div className="url-container">
            <img className="track-edit-image" src={trackEdit} alt="trackEdit" width={15} height={15} />
            <p>Track</p>
          </div>
          </div> : 


          requestRecievedDate && !dataOfReq?.tracking_link ?
          <div>
          <div className="date-picker-clear-container">
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(formattedDate, "MM/DD/YYYY")}
              disabledDate={disabledDate}
              disabled={
                !requestDate ||
                requestDate == " " ||
                requestDate == "" ||
                requestDate == null || requestDate == "null"
              }
              onChange={onDateChange}
              format="MM/DD/YYYY"
            />
            <Tooltip placement="top" title={"Reset"}>
            <img className="reset-icon" onClick={onResetClick} alt="reset-icon" src={resetCalendar} />
          </Tooltip>
          </div> 
          <div onClick={onTrackClick} className="url-container">
            <img className="track-edit-image" src={trackEdit} alt="trackEdit" width={15} height={15} />
            <p>Track</p>
          </div>
          </div>
          :

          requestRecievedDate && dataOfReq?.tracking_link ?
          <div>
          <div className="date-picker-clear-container">
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(formattedDate, "MM/DD/YYYY")}
              disabledDate={disabledDate}
              disabled={
                !requestDate ||
                requestDate == " " ||
                requestDate == "" ||
                requestDate == null || requestDate == "null"
              }
              onChange={onDateChange}
              format="MM/DD/YYYY"
            />
            <Tooltip placement="top" title={"Reset"}>
            <img className="reset-icon" onClick={onResetClick} alt="reset-icon" src={resetCalendar} />
          </Tooltip>
          </div>
          <div className="req-url-container">
            <img onClick={() => onTrackClickForUrl(dataOfReq?.tracking_link)} className="track-edit-image" src={trackEdit} alt="trackEdit" width={15} height={15} />
            <p className="url-text" onClick={() => onTrackUrlClick(dataOfReq?.tracking_link)}>{dataOfReq?.tracking_link.slice(0, 15)}</p>
            <Tooltip overlayInnerStyle={{
                  height: "fit-content",
                  color: "white",
                  marginLeft: "10px",
                }}
                placement={"top"}
                trigger="hover"
                title={"Reset"}>
            <img onClick={onResetUrl} src={ResetUrl} alt="ResetUrl" width={15} height={15} />
            </Tooltip>
          </div>
          </div> :

          requestDate && !dataOfReq?.tracking_link ?
          <div>
            <DatePicker
            allowClear={false}
            onChange={onDateChange}
            disabledDate={disabledDate}
            disabled={
              !requestDate ||
              requestDate == " " ||
              requestDate == "" ||
              requestDate == null || requestDate == "null"
            }
            format="MM/DD/YYYY"
          />
          <div onClick={onTrackClick} className="url-container">
          <Tooltip overlayInnerStyle={{
            height: "fit-content",
            color: "white",
            marginLeft: "10px",
            }}
            placement={"top"}
            trigger="hover"
            title={"Edit"}>
            <img className="track-edit-image" src={trackEdit} alt="trackEdit" width={15} height={15} />
          </Tooltip>
            <p>Track</p>
          </div>
          </div> :

          requestDate && dataOfReq?.tracking_link ?
          <div>
            <DatePicker
            allowClear={false}
            onChange={onDateChange}
            disabledDate={disabledDate}
            disabled={
              !requestDate ||
              requestDate == " " ||
              requestDate == "" ||
              requestDate == null || requestDate == "null"
            }
            format="MM/DD/YYYY"
          />
          <div className="req-url-container">
            <img onClick={() => onTrackClickForUrl(dataOfReq?.tracking_link)} className="track-edit-image" src={trackEdit} alt="trackEdit" width={15} height={15} />
            <p className="url-text" onClick={() => onTrackUrlClick(dataOfReq?.tracking_link)}>{dataOfReq?.tracking_link.slice(0, 15)}</p>
            <Tooltip overlayInnerStyle={{
                  height: "fit-content",
                  color: "white",
                  marginLeft: "10px",
                }}
                placement={"top"}
                trigger="hover"
                title={"Reset"}>
            <img onClick={onResetUrl} src={ResetUrl} alt="ResetUrl" width={15} height={15} />
            </Tooltip>
          </div>
          </div>
          : <></>
    }

      </div>
      <Modal title="Do you want to Reset the Promised Date?"
          open={isModalOpen}
          okText={"save"} onCancel={handleCancel}>
            <div className="dashboard-modal-buttons">
          <Button className="modal-yes-button" onClick={onContinue}>
            Yes
          </Button>
          <Button className="modal-cancel-btn" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </Modal>
      <Modal title="Do you want to Reset the Tracking Url?"
          open={resetTrackingModalOpen}
          okText={"save"} onCancel={onResetHandleCancel}>
            <div className="dashboard-modal-buttons">
          <Button className="modal-yes-button" onClick={onResetUrlSave}>
            Yes
          </Button>
          <Button className="modal-cancel-btn" onClick={onResetHandleCancel}>
            Cancel
          </Button>
        </div>
      </Modal>
      <Modal title=""
          open={isTrackModalOpen}
          okText={"save"} onCancel={handleTrackCancel}>
            <div className="track-modal-details-first-container">
              <div className="track-modal-details-container">
                <p className="track-details-text">Style :</p>
                <p className="track-details">{itemData?.style}</p>
              </div>
              <div className="track-modal-details-container">
                <p className="track-details-text">Request No :</p>
                <p className="track-details">{dataOfReq?.request_no}</p>
              </div>
            </div>
            <div className="track-modal-details-second-container">
              <div className="track-modal-details-container">
                <p className="track-details-text">Vendor :</p>
                <p className="track-vendor-name">{itemData?.factory}</p>
              </div>
              <div className="track-modal-details-container">
                <p className="track-details-text">Color :</p>
                <p className="track-color">{itemData?.color_code}</p>
              </div>
              <div className="track-modal-details-container">
                <p className="track-details-text">Size :</p>
                <p className="track-size">{itemData?.size}</p>
              </div>
            </div>
            
            <div className="track-url-container">
              <InputField value={trackingUrl} onChange={onTrackUrlChange} placeholder={"Enter tracking Url..."} />
              <Button className="track-modal-yes-button" onClick={() => onTrackSave(dataOfReq?.tracking_link)}>
                Save
              </Button>
            </div>
            <div className="dashboard-modal-buttons">
          
          {/* <Button className="modal-cancel-btn" onClick={handleTrackCancel}>
            Cancel
          </Button> */}
        </div>
      </Modal>
    </div>
  );
};

export default ExFactoryDate;
