import { useMsal } from "@azure/msal-react";
import { Button, Modal } from "antd";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getGraphData,
  profileDetail,
} from "../../Redux/actions/Profile/Profile";
import { toast } from "../../UIComponents/Toast";
import Logo from "../../images/logo.jpg";
import { ProfileData } from "../Login/ProfileData";
import "./Header.css";

const Header = () => {
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [logoutModalVisible, setLogoutModalVisible] = useState(false);
  const dataFromGraph = useSelector(
    (state) => state?.Profile?.graphCompleteData
  );
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const profileModal = () => {
    setIsModalOpen(true);
  };

  const handleLogoutCancel = () => {
    setLogoutModalVisible(false);
  };

  const handleLogout = () => {
    setLogoutModalVisible(true);
  };

  const onComfirmHandle = () => {
    localStorage.removeItem("DashboardData");
    localStorage.removeItem("currentPage");
    localStorage.removeItem("SrfSearchData");
    localStorage.removeItem("srtActiveKey");
    localStorage.removeItem("filteredDataForSample");
    // localStorage.removeItem("activeKey");
    localStorage.removeItem("srtData");
    localStorage.removeItem("LoginToken");
    localStorage.removeItem("RequestSend");
    localStorage.removeItem("scrollYaxis");
    localStorage.removeItem("srtMassData");
    localStorage.removeItem("SampleNotes");
    Cookies.remove("access_token");
    instance
      .logoutRedirect({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/",
      })
      .then((resp) => {
        toast.success("Successfully LoggedOut");
        console.log("got logged out");
      })
      .catch((err) => {
        console.log("on logout error", err);
      });
  };
  let secure_token = Cookies.get("access_token");
  useEffect(() => {
    if (secure_token) {
      dispatch(getGraphData());
    }
  }, []);

  useEffect(() => {
    if (dataFromGraph) {
      const values = Object.values(dataFromGraph);
      const hasValues = values.length > 0;
      if (hasValues) {
        dispatch(profileDetail(dataFromGraph?.userPrincipalName));
      }
    }
  }, [dataFromGraph]);

  return (
    <div>
      <div className="header">
        <a
          href="https://roycetoo.sharepoint.com/sites/ConcepttoPurchase-AppDevelopment/Shared%20Documents/Forms/AllItems.aspx?FolderCTID=0x0120005BF34F38566DAA49ABABD1BBD3765CF5&id=%2Fsites%2FConcepttoPurchase%2DAppDevelopment%2FShared%20Documents%2FTesting%2Fportal%2Ddocuments%2FLibrary%2FEddieBauer&viewid=5a4dce61%2D20ba%2D46b6%2Dabd3%2D3e73f96d7dfb"
          target="_blank"
        >
          <img className="roycetoo-logo" src={Logo} alt="Roycetoologo" />
        </a>
        <div className="header-profile-container">
          {dataFromGraph?.displayName ? (
            <>
              <p className="profile-logo">
                {dataFromGraph?.displayName.substring(0, 1)}
              </p>
              <div className="profile-details">
                <p className="profile-user">{dataFromGraph?.displayName}</p>
                <p className="name-logout">
                  <span className="profile-name" onClick={profileModal}>
                    Profile
                  </span>
                  <span>|</span>
                  <span className="profile-logout" onClick={handleLogout}>
                    Logout
                  </span>
                </p>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      {/* profile Modal */}
      <Modal
        title="My Profile"
        open={isModalOpen}
        okText={"save"}
        onCancel={handleCancel}
      >
        <hr />
        <br />
        <ProfileData graphData={dataFromGraph} />
        <div className="profile-buttons">
          <Button className="profile-yes-btn" onClick={handleCancel}>
            Close
          </Button>
        </div>
      </Modal>

      {/* Logout Modal */}

      <Modal
        title="Are you sure you want to logout?"
        open={logoutModalVisible}
        okText={"save"}
        onCancel={handleLogoutCancel}
      >
        <div className="logout-buttons">
          <Button className="profile-cancel-btn" onClick={handleLogoutCancel}>
            Cancel
          </Button>
          <Button className="profile-yes-btn" onClick={onComfirmHandle}>
            Confirm
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Header;
