import React from 'react'
import "./UnsolicitedInput.css"
import { Input } from 'antd'

const UnsolicitedInput = ({name, value, onchange, onclick}) => {
  return (
    <div>
        <Input
          onInput={(e) => {
            const regex = /^[a-zA-Z0-9\s]*$/;
            e.target.value = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
            if (!regex.test(e.target.value)) {
              e.preventDefault();
            }
          }}
          maxLength={name === "qty_prs_received" ? 3 : 200}
          name={name}
          value={value}
          onChange={onchange}
          onClick={onclick}
        />

    </div>
  )
}

export default UnsolicitedInput