import React from 'react'
import "./UnsolicitedReceivedDate.css"
import { DatePicker } from 'antd'
import dayjs from "dayjs";
import "./UnsolicitedReceivedDate.css"

const UnsolicitedReceivedDate = ({ createdDate,
    recievedDate,
    onDateChange
}) => {
    const datePickerFunction = () => {
        if (recievedDate) {
          const dateObj = dayjs(recievedDate);
          const formattedDate = dateObj.format("MM/DD/YYYY");
          return (
            <DatePicker
              allowClear={true}
              defaultValue={dayjs(formattedDate, "MM/DD/YYYY")}
              onChange={onDateChange}
              format="MM/DD/YYYY"
            />
          );
        } else {
          return (
            <DatePicker
              allowClear={true}
              onChange={onDateChange}
              format="MM/DD/YYYY"
            />
          );
        }
      };
  return (
    <div className='uncoslicited-receivedate-container'>
        {datePickerFunction()}
    </div>
  )
}

export default UnsolicitedReceivedDate