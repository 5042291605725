import React, { useRef, useState } from "react";
import DropDown from "../../../UIComponents/DropDown/DropDown";
import "./SalesSamples.css";
import SearchInput from "../../../UIComponents/SearchInput/SearchInput";
import { Button } from "antd";
import SrtTable from "../Table/SrtTable";
import { useSelector, useDispatch } from "react-redux";
import {
  massSearchFilter,
  resetMassFilter,
  resetSeasonFilter,
  resetSrtData,
  seasonSearchFilter,
  selectUnselectSrtTableRows,
  srtBulkSave,
  srtExport,
  srTExport,
  srtSeasonSearchFilter,
} from "../../../Redux/actions/Srt/Srt";
import Select from "../../../UIComponents/DropDown/DropDown";
import { DownloadOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { getSrtInitialList } from "../../../Redux/actions/Srt/Srt";
import { SyncOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";
import { toast } from "../../../UIComponents/Toast";
import SalesTable from "./SalesTable";

const SalesSamples = () => {
  const dispatch = useDispatch();
  const fileRef = useRef();
  const [searchFilter, setSearchFilter] = useState();
  const [brandFilter, setBrandFilter] = useState();
  const [teamFilter, setTeamFilter] = useState();
  const [statusFilter, setStatusFilter] = useState();
  const [vendorFilter, setVendorFilter] = useState();
  const [colorFilter, setColorFilter] = useState();
  const [sizeFilter, setSizeFilter] = useState();
  const [seasonFilter, setSeasonFilter] = useState();
  const [singleSeason, setSingleSeason] = useState([]);
  const [singleColor, setSingleColor] = useState([]);
  const [singleSize, setSingleSize] = useState([]);
  const [singleFactory, setSingleFactory] = useState([]);

  const srtDataList = useSelector((state) => state?.Srt?.getSrtDataList);
  const exportArraySrt = useSelector((state) => state?.Srt?.srtExportArrayData);
  const srtRefresh = useSelector((state) => state?.Srt?.srtRefreshSpinner);
  const srtDataForApi = JSON.parse(localStorage.getItem("srtData"));
  // console.log("srtDataForApi", srtDataForApi);
  const styleListData = useSelector((state) => state?.Dashboard?.styleList);
  const brandNameFromApi = useSelector((state) => state?.Srt?.srtBrandNames);
  const seasonFilterApiData = useSelector(
    (state) => state?.Srt?.seasonSearchFilterResult
  );
  const srtSeasonData = useSelector((state) => state?.Srt?.srtSeasonSearch);

  // useEffect(() => {
  //   dispatch(
  //     getSrtInitialList(
  //       "",
  //       "",
  //       "",
  //       "",
  //       1
  //     )
  //   );
  // }, []);

  const intialSearch = () => {
    if (Array.isArray(seasonFilterApiData) && seasonFilterApiData.length > 0) {
      return seasonFilterApiData;
    } else if (Array.isArray(srtSeasonData) && srtSeasonData.length > 0) {
      return srtSeasonData;
    } else if (Array.isArray(srtDataList)) {
      return srtDataList;
    } else {
      return [];
    }
  };

  useEffect(() => {
    const searchData = intialSearch();

    if (Array.isArray(searchData)) {
      const colorArray = searchData.map((item) => item?.color_code || "");
      setSingleColor([...new Set(colorArray)]);

      const sizeArray = searchData.map((item) => item?.size || "");
      setSingleSize([...new Set(sizeArray)]);
    } else {
      console.error("intialSearch did not return an array:", searchData);
    }
  }, [seasonFilterApiData, srtSeasonData, srtDataList]);

  useEffect(() => {
    if (intialSearch()) {
      if (Array.isArray(styleListData)) {
        const seasonArray = styleListData?.map((item) => item?.season || "");
        setSingleSeason([...new Set(seasonArray)]);

        const vendorArray = intialSearch()
          .map((item) => item?.factory)
          .filter(Boolean); // Filter out falsy values

        const uniqueNames = new Set();

        vendorArray.forEach((names) => {
          if (Array.isArray(names)) {
            names.forEach((name) => uniqueNames?.add(name));
          } else {
            uniqueNames.add(names);
          }
        });

        const uniqueArray = Array.from(uniqueNames).sort();
        setSingleFactory(uniqueArray);
      } else {
        console.error("styleListData is not an array:", styleListData);
      }
    }
  }, [styleListData, intialSearch()]);

  const brands = [
    {
      value: "Dickies",
      label: "Dickies",
    },
    {
      value: "Saucony",
      label: "Saucony",
    },
    {
      value: "EddieBauer",
      label: "EddieBauer",
    },
    {
      value: "PD",
      label: "PD & Redevelopment",
    },
  ];

  const seasons = [
    {
      value: "F24",
      label: "F24",
    },
    {
      value: "S25",
      label: "S25",
    },
    {
      value: "F25",
      label: "F25",
    },
    {
      value: "S26",
      label: "S26",
    },
  ];

  const team = [
    {
      value: "",
      label: "-",
    },
    {
      value: "BRAND",
      label: "BRAND",
    },
    {
      value: "DESIGN",
      label: "DESIGN",
    },
    {
      value: "PD",
      label: "PD",
    },
    {
      value: "QA",
      label: "QA",
    },
  ];

  const statusofsrt = [
    {
      value: "",
      label: "-",
    },
    {
      value: "Set_up_Ready",
      label: "Set-up Ready",
    },
    {
      value: "Approved",
      label: "Approved",
    },
    {
      value: "Override",
      label: "Override",
    },
    {
      value: "N/A",
      label: "N/A",
    },
    {
      value: teamFilter !== "BRAND" ? "Rejected" : "Dropped",
      label: teamFilter !== "BRAND" ? "Rejected" : "Dropped",
    },
  ];

  const onSearchChange = (e) => {
    setSearchFilter(e.target.value);
  };

  const onBrandChange = (value) => {
    setBrandFilter(value);
  };

  const onTeamChange = (value) => {
    setTeamFilter(value);
  };

  const onStatusChange = (value) => {
    setStatusFilter(value);
  };

  const onSeasonChange = (value) => {
    dispatch(srtSeasonSearchFilter("", value == undefined ? "" : value, 1));
    setSeasonFilter(value);
    dispatch(resetMassFilter([]));
    dispatch(resetSrtData([]));
  };

  const onFactoryChange = (value) => {
    setVendorFilter(value);
  };

  const onColorChange = (value) => {
    setColorFilter(value);
  };

  const onSizeChange = (value) => {
    setSizeFilter(value);
  };

  const onSubmitClick = () => {
    localStorage.setItem(
      "srtMassData",
      JSON.stringify({
        searchFilter: searchFilter,
        brandfilter: brandFilter,
        colorFilter: colorFilter,
        sizeFilter: sizeFilter,
        statusFilter: statusFilter,
        vendorFilter: vendorFilter,
        sale_samplefilter: 1,
        seasonFilter: seasonFilter,
      })
    );
    dispatch(
      massSearchFilter(
        searchFilter ? searchFilter : "",
        brandFilter ? brandFilter : "",
        colorFilter ? colorFilter : "",
        sizeFilter ? sizeFilter : "",
        statusFilter ? statusFilter : "",
        vendorFilter ? vendorFilter : "",
        1,
        seasonFilter ? seasonFilter : ""
      )
    );
  };
  const exportToExcelDickies = () => {
    if (searchFilter && vendorFilter && exportArraySrt.length > 0) {
      toast.error("!Please select either style and Vendor Filter or style ids");
    } else if (
      vendorFilter === null ||
      (vendorFilter === undefined &&
        searchFilter &&
        exportArraySrt.length === 0)
    ) {
      toast.error("!Please select either style and Vendor Filter or style ids");
    } else if (
      searchFilter === null ||
      (searchFilter === undefined &&
        vendorFilter &&
        exportArraySrt.length === 0)
    ) {
      toast.error("!Please select either style and Vendor Filter or style ids");
    } else {
      dispatch(
        srtExport({
          style:
            searchFilter === null || searchFilter === undefined
              ? ""
              : searchFilter,
          factory:
            vendorFilter === null || vendorFilter === undefined
              ? ""
              : vendorFilter,
          style_pids: exportArraySrt,
          sale_sample: "1",
        })
      );
      dispatch(selectUnselectSrtTableRows([]));
      dispatch(srtEXportArray([]));
    }
  };
  const onRefresh = () => {
    localStorage.removeItem("srtMassData");
    localStorage.removeItem("currentPage");
    localStorage.setItem(
      "srtData",
      JSON.stringify({
        brand: "",
        style: "",
        factory: "",
        rejected_date: "",
        sale_sample: 1,
      })
    );
    dispatch(
      getSrtInitialList(
        srtDataForApi?.brand ? srtDataForApi?.brand : "",
        srtDataForApi?.style ? srtDataForApi?.style : "",
        srtDataForApi?.factory ? srtDataForApi?.factory : "",
        srtDataForApi?.rejected_date ? srtDataForApi?.rejected_date : "",
        1,
        srtDataForApi?.season ? srtDataForApi?.season : ""
      )
    );
    dispatch(resetSeasonFilter([]));
    dispatch(resetMassFilter([]));
    setSearchFilter();
    setBrandFilter();
    setColorFilter();
    setSizeFilter();
    setStatusFilter();
    setVendorFilter();
  };

  const onSearchEnter = (event) => {
    if (event.key === "Enter") {
      if (searchFilter === null) {
        toast.error("Please Enter Details");
      } else {
        localStorage.setItem(
          "srtMassData",
          JSON.stringify({
            searchFilter: searchFilter,
            brandfilter: "",
            colorFilter: "",
            sizefilter: "",
            statusFilter: "",
            vendorFilter: "",
            sale_samplefilter: 1,
            seasonFilter: "",
          })
        );
        dispatch(
          massSearchFilter(
            searchFilter ? searchFilter : "",
            "",
            "",
            "",
            "",
            "",
            1,
            ""
          )
        );
        setSearchFilter();
      }
    }
  };

  const onSrtBulkUpload = (e) => {
    let formData = new FormData();
    formData.append("files", e.target.files[0]);
    formData.append("sale_sample", "1");
    dispatch(srtBulkSave(formData, brandNameFromApi));
    e.target.value = null;
    formData = new FormData();
  };

  return (
    <div className="dickies-main-container">
      <div className="dickies-first-two-dropdowns">
        <div className="dickies-drop-down-one">
          <DropDown
            allowClear
            onChange={onSeasonChange}
            placeholder={"Season"}
            option={seasons}
            value={seasonFilter}
          />
        </div>
        <h2>SALES SAMPLES</h2>
        <div className="dickies-drop-down-two">
          <Button icon={<DownloadOutlined />} onClick={exportToExcelDickies}>
            Export
          </Button>
        </div>
      </div>

      {/* search dropdown button */}
      <div className="search-dropdown-button">
        <div className="dickies-search-input">
          <SearchInput
            maxLength={10}
            onKeyPress={onSearchEnter}
            onChange={onSearchChange}
            value={searchFilter}
            placeholder={"Search by Parent Style"}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, "");
            }}
          />
        </div>
        <div className="dropdown-width dickies-drop-down-five">
          <Select
            allowClear
            placeholder={"Vendor"}
            data={singleFactory}
            value={vendorFilter}
            onChange={onFactoryChange}
          ></Select>
        </div>
        <div className="dropdown-width dickies-drop-down-five">
          <Select
            allowClear
            placeholder={"Color"}
            data={singleColor}
            value={colorFilter}
            onChange={onColorChange}
          ></Select>
        </div>
        <div className="dropdown-width dickies-drop-down-five">
          <Select
            allowClear
            placeholder={"Size"}
            data={singleSize}
            value={sizeFilter}
            onChange={onSizeChange}
          ></Select>
        </div>
        <div className="dropdown-width dickies-drop-down-five">
          <DropDown
            allowClear
            onChange={onBrandChange}
            placeholder={"Brand"}
            value={brandFilter}
            option={brands}
          />
        </div>
        {/* <div className="dropdown-width dickies-drop-down-five">
          <DropDown
            allowClear
            onChange={onTeamChange}
            placeholder={"Team"}
            option={team}
            value={teamFilter}
          />
        </div> */}
        <div className="dropdown-width dickies-drop-down-five">
          <DropDown
            allowClear
            onChange={onStatusChange}
            placeholder={"Status"}
            option={statusofsrt}
            value={statusFilter}
          />
        </div>
        <div className="sync-icon">
          <SyncOutlined spin={srtRefresh} onClick={onRefresh} />
        </div>
        <div>
          <Button
            onClick={onSubmitClick}
            className="dickies-button"
            children={"SUBMIT"}
          />
        </div>
        <div>
          <Button
            className="dickies-button"
            onClick={() => fileRef.current.click()}
          >
            Bulk Update
          </Button>
          <input
            type="file"
            onChange={onSrtBulkUpload}
            accept="image/jpeg/png/jpg , application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            ref={fileRef}
            hidden
          />
        </div>
      </div>
      <div className="srt-table-container">
        <SalesTable />
      </div>
    </div>
  );
};

export default SalesSamples;
