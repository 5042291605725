import { Modal, Button, Checkbox, Radio, Table, Tooltip, Input } from "antd";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  DeleteEmailDetails,
  DownloadEmail,
  SaveEmailDetails,
  changingTabsWithCustomButton,
  closeEmailModal,
  dashboardStatusSaver,
  dashboardStatusUpdate,
  droaChecked,
  droaStatusSave,
  emailStatusUpdate,
  getSavedEmail,
  roaStatusSave,
  sendEmail,
  techPackEmailUpdate,
  tpDropdownStatusUpdate,
} from "../../Redux/actions/Dashboard/Dashboard";
import { resetSrfSearchData } from "../../Redux/actions/Srf/Srf";
import {
  resetMassFilter,
  resetSeasonFilter,
  resetSrtSeasonFilter,
  srtSearchFilter,
} from "../../Redux/actions/Srt/Srt";
import Cross from "../../images/cross.png";
import Tick from "../../images/tick.png";
import YellowDot from "../../images/yellowdot.png";
import "./AccordionContent.css";
import { useEffect } from "react";
import Info from "../../images/info.png";
import {
  CloseOutlined,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import InputField from "../../UIComponents/Input/Input";
import CommonButton from "../../UIComponents/Button/Button";
import { toast } from "../../UIComponents/Toast";
import Cookies from "js-cookie";

const columns = [
  {
    title: <p className="remove-color">hi</p>,
    dataIndex: "color",
    key: "color",
    width: 240,
  },
  {
    title: <p className="remove-color">hi</p>,
    dataIndex: "size",
    key: "size",
    width: 240,
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    width: 540,
  },
  {
    title: "Qty",
    dataIndex: "quantity",
    key: "quantity",
    width: 240,
  },
  {
    title: "BRAND",
    dataIndex: "brand",
    key: "brand",
    width: 40,
  },
  {
    title: "DESIGN",
    dataIndex: "design",
    key: "design",
    width: 40,
  },
  {
    title: "PD",
    dataIndex: "pd",
    key: "pd",
    width: 40,
  },
  {
    title: "QA",
    dataIndex: "qa",
    key: "qa",
    width: 40,
  },
];

const AccordionContent = () => {
  const { TextArea } = Input;
  const fileRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const emailStatusData = useSelector((state) => state?.Dashboard);
  // console.log("stylelist from emailStatusData", emailStatusData);
  const singleRowDataFromDashboard = useSelector(
    (state) => state?.Dashboard?.dashboardSingleRowData
  );
  const styleDetailsDashboard = useSelector(
    (state) => state?.Dashboard?.styleDetailsData
  );
  console.log(
    "singleRowDataFromDashboard",
    singleRowDataFromDashboard,
    styleDetailsDashboard
  );
  const statusUpdatedData = useSelector(
    (state) => state?.Dashboard?.dashboardStatus
  );
  console.log("statusUpdatedData", statusUpdatedData);
  const droaCheckedValue = useSelector(
    (state) => state?.Dashboard?.droaCheckedStatus
  );
  console.log("droaCheckedValue", droaCheckedValue);
  const sendEmailResponse = useSelector(
    (state) => state?.Dashboard?.sendEmailUpdatedStatus
  );
  console.log("sendEmailResponse", sendEmailResponse);

  const emailData = useSelector((state) => state?.Dashboard?.savedEmailData);
  console.log("emailData", emailData);

  const emailModalToggle = useSelector(
    (state) => state?.Dashboard?.closeModalEmail
  );
  const DashboardEmailData = localStorage.getItem("DashboardEmailData");
  const parsedDashboardData = JSON.parse(DashboardEmailData);
  console.log("DashboardEmailData:", parsedDashboardData);

  const [dashboardLocalData, setDashboardLocalData] = useState({
    attach_tech_pack: "",
    attach_QAV: "",
    add_cc_to_email: "",
    attach_other_files: "",
    request_notes: "",
  });
  useEffect(() => {
    localStorage.setItem(
      "DashboardEmailData",
      JSON.stringify(dashboardLocalData)
    );
  }, [dashboardLocalData]);

  useEffect(() => {
    if (dashboardLocalData) {
      setRadioButtonValue(dashboardLocalData?.attach_tech_pack);
      setQavButtonValue(dashboardLocalData?.attach_QAV);
      setCCList(dashboardLocalData?.send_this_SRF_to_vendor);
      const fileNames = (dashboardLocalData.attach_other_files || []).map(
        (file) => file.content
      );
      setFileName(fileNames);
    }
  }, []);

  const [showTooltip, setShowTooltip] = useState(false);
  const [vendorName, setVendorName] = useState("");
  const [activeButton, setActiveButton] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRoaModalOpen, setisRoaModalOpen] = useState(false);
  const [isDroaReceivedModalOpen, setisDroaReceivedModalOpen] = useState(false);
  const [dataFotDROA, setDataForDROA] = useState({});
  const [checked, setChecked] = useState();
  const [roaReceived, setRoaReceived] = useState();
  const [DroaReceived, setDroaReceived] = useState();
  const [emailModal, setEmailModal] = useState(emailModalToggle);
  const [vendorNameForEmail, setVendorNameForEmail] = useState(null);
  const [file, setFile] = useState([]);
  const [fileName, setFileName] = useState([]);
  const [radioButtonValue, setRadioButtonValue] = useState(0);
  const [qavButtonValue, setQavButtonValue] = useState(0);
  const [textNote, setTextNote] = useState("");
  const [ccValue, setCCValue] = useState("");
  const [ccList, setCCList] = useState([]);
  const factory = localStorage.getItem("vendorName");

  console.log("factory", factory);
  console.log("fileName", fileName);

  console.log("styleDetailsDashboard", styleDetailsDashboard);

  const arrayData = styleDetailsDashboard?.vendors
    ?.map((item, index) => {
      const itemData = item?.srt_status?.request_details?.map((reqData) => {
        return {
          id: index,
          ...reqData,
        };
      });
      return itemData;
    })
    .flat();

  useEffect(() => {
    styleDetailsDashboard?.vendors?.map((item) => {
      console.log("itemvendor", item);
      dispatch(droaChecked(item));
    });
  }, [styleDetailsDashboard]);

  useEffect(() => {
    if (emailData && Object.keys(emailData).length > 0) {
      setTextNote(emailData?.request_notes);
      setFileName(emailData?.file_names);
      setRadioButtonValue(emailData?.tech_pack_attach);
      setQavButtonValue(emailData?.qav_attach);
    } else {
      setTextNote("");
      setFileName([]);
      setRadioButtonValue(0);
      setQavButtonValue(0);
    }
  }, [emailData]);

  useEffect(() => {
    if (
      sendEmailResponse &&
      sendEmailResponse?.success === "Mail sent successfully"
    ) {
      setRadioButtonValue(0);
      setQavButtonValue(0);
      setTextNote("");
      // setFileName([]);
      // setFile([]);
      let formData = new FormData();
      dispatch(closeEmailModal(false));
    }
  }, [sendEmailResponse]);

  const data = [];
  {
    const filteredTooltipData = arrayData?.filter(
      (item) => item?.id == activeButton
    );
    filteredTooltipData?.map((reqData, index) => {
      console.log("reqDatareqData", reqData);
      return data.push({
        key: index,
        color: (
          <p className="color-tooltip">
            Color :{" "}
            <span className="tooltip-colorcode">{reqData?.color_code}</span>
          </p>
        ),
        size: (
          <p className="size-tooltip">
            Size: <span className="tooltip-sizecode">{reqData?.size}</span>
          </p>
        ),
        date: (
          <p className="date-of-tooltip">
            <span>
              {reqData?.request_date === " " || reqData?.request_date === ""
                ? "-"
                : reqData?.request_date}
              -
              <br />
            </span>
            <span>
              {reqData?.request_recieved === " " ||
              reqData?.request_recieved === "" ||
              reqData?.request_recieved === null
                ? "-"
                : reqData?.request_recieved}
            </span>
          </p>
        ),
        quantity: <p className="tooltip-qty">{reqData?.Qty}</p>,
        brand: (
          <div className="status-icons">
            {reqData?.brand_status === "N/A" ? (
              <img className="tooltip-icons" src={Tick} alt="tick" />
            ) : reqData?.brand_status === "Set_up_Ready" ? (
              <img className="tooltip-icons" src={Tick} alt="tick" />
            ) : reqData?.brand_status === "Override" ? (
              <img className="tooltip-icons" src={Tick} alt="tick" />
            ) : reqData?.brand_status === "Dropped" ? (
              <img className="tooltip-icons" src={Cross} alt="Cross" />
            ) : reqData?.brand_status === "Rejected" ? (
              <img className="tooltip-icons" src={Cross} alt="Cross" />
            ) : reqData?.brand_status === "0" ? (
              <img className="tooltip-icons" src={YellowDot} alt="YellowDot" />
            ) : (
              <p className="no-icon">-</p>
            )}
          </div>
        ),
        design: (
          <div className="status-icons">
            {reqData?.design_status === "N/A" ? (
              <img className="tooltip-icons" src={Tick} alt="tick" />
            ) : reqData?.design_status === "Approved" ? (
              <img className="tooltip-icons" src={Tick} alt="tick" />
            ) : reqData?.design_status === "Override" ? (
              <img className="tooltip-icons" src={Tick} alt="tick" />
            ) : reqData?.design_status === "Dropped" ? (
              <img className="tooltip-icons" src={Cross} alt="Cross" />
            ) : reqData?.design_status === "Rejected" ? (
              <img className="tooltip-icons" src={Cross} alt="Cross" />
            ) : reqData?.design_status === "0" ? (
              <img className="tooltip-icons" src={YellowDot} alt="YellowDot" />
            ) : (
              <p className="no-icon">-</p>
            )}
          </div>
        ),
        pd: (
          <div className="status-icons">
            {reqData?.pd_status === "N/A" ? (
              <img className="tooltip-icons" src={Tick} alt="tick" />
            ) : reqData?.pd_status === "Approved" ? (
              <img className="tooltip-icons" src={Tick} alt="tick" />
            ) : reqData?.pd_status === "Override" ? (
              <img className="tooltip-icons" src={Tick} alt="tick" />
            ) : reqData?.pd_status === "Dropped" ? (
              <img className="tooltip-icons" src={Cross} alt="Cross" />
            ) : reqData?.pd_status === "Rejected" ? (
              <img className="tooltip-icons" src={Cross} alt="Cross" />
            ) : reqData?.pd_status === "0" ? (
              <img className="tooltip-icons" src={YellowDot} alt="YellowDot" />
            ) : (
              <p className="no-icon">-</p>
            )}
          </div>
        ),
        qa: (
          <div className="status-icons">
            {reqData?.qa_status === "N/A" ? (
              <img className="tooltip-icons" src={Tick} alt="tick" />
            ) : reqData?.qa_status === "Approved" ? (
              <img className="tooltip-icons" src={Tick} alt="tick" />
            ) : reqData?.qa_status === "Override" ? (
              <img className="tooltip-icons" src={Tick} alt="tick" />
            ) : reqData?.qa_status === "Dropped" ? (
              <img className="tooltip-icons" src={Cross} alt="Cross" />
            ) : reqData?.qa_status === "Rejected" ? (
              <img className="tooltip-icons" src={Cross} alt="Cross" />
            ) : reqData?.qa_status === "0" ? (
              <img className="tooltip-icons" src={YellowDot} alt="tick" />
            ) : (
              <p className="no-icon">-</p>
            )}
          </div>
        ),
      });
    });
  }

  const onSampleTrackerHandle = () => {
    localStorage.setItem(
      "srtData",
      JSON.stringify({
        brand: styleDetailsDashboard?.brand,
        style: styleDetailsDashboard?.style,
        factory: vendorName,
        rejected_date: "",
        sale_sample: 0,
        season: styleDetailsDashboard?.season,
      })
    );
    dispatch(resetMassFilter());
    dispatch(resetSeasonFilter());
    dispatch(resetSrtSeasonFilter());
    // window.open("/sample-receipt-tracker", '_blank');
    // dispatch(srtSearchFilter(styleDetailsDashboard?.brand,navigate))
    // navigate("/sample-receipt-tracker");
    // dispatch(changingTabsWithCustomButton("true.3"));
    // setShowTooltip(false);
    // localStorage.setItem("activeKey", "3");
    if (
      singleRowDataFromDashboard?.brand == "DICKI" ||
      singleRowDataFromDashboard?.brand == "DICKC" ||
      singleRowDataFromDashboard?.brand == "DICKW" ||
      singleRowDataFromDashboard?.brand == "DICKG"
    ) {
      localStorage.setItem("srtActiveKey", "1");
      window.open("/sample-receipt-tracker/dickies", "_blank");
      // navigate("/sample-receipt-tracker/dickies");
    } else if (singleRowDataFromDashboard?.brand == "SAUC") {
      localStorage.setItem("srtActiveKey", "2");
      window.open("/sample-receipt-tracker/saucony", "_blank");
      // navigate("/sample-receipt-tracker/saucony");
    } else if (singleRowDataFromDashboard?.brand == "EDBR") {
      localStorage.setItem("srtActiveKey", "3");
      window.open("/sample-receipt-tracker/eddie-bauer", "_blank");
      // navigate("/sample-receipt-tracker/eddie-bauer");
    } else if (singleRowDataFromDashboard?.brand == "PD") {
      localStorage.setItem("srtActiveKey", "4");
      window.open("/sample-receipt-tracker/pd", "_blank");
      // navigate("/sample-receipt-tracker/pd");
    } else {
      localStorage.setItem("srtActiveKey", "1");
      window.open("/sample-receipt-tracker/dickies", "_blank");
      // navigate("/sample-receipt-tracker/dickies");
    }
    // window.open("/sample-receipt-tracker", '_blank');
  };

  const onGoToSRFHandle = (data) => {
    console.log("datasrf", data);
    dispatch(changingTabsWithCustomButton("true.2"));
    localStorage.removeItem("SrfSearchData");
    const style = styleDetailsDashboard?.style;
    const factory = data?.vendorname;
    const season = styleDetailsDashboard?.season;
    const brand = styleDetailsDashboard?.brand;
    localStorage.setItem(
      "DashboardData",
      JSON.stringify({
        style: style,
        factory: factory,
        season: season,
        brand: brand,
      })
    );
    Cookies.set(
      "DashboardSrfData",
      JSON.stringify({
        style: style,
        factory: factory,
        season: season,
        brand: brand,
      }),
      {
        expires: 1,
        secure: true,
        // sameSite: "Strict",
      }
    );
    dispatch(resetSrfSearchData([]));
    localStorage.setItem("RequestSend", false);
    navigate("/sample-request-form");
  };

  const expandedRowRender = (record) => (
    <div className="tooltip-notes">
      <h4 className="tooltip-note-header">Note :</h4>
      {arrayData?.map((reqData, index) => {
        return (
          <p className="tooltip-note">
            {reqData?.request_notes ? reqData?.request_notes : "-"}
          </p>
        );
      })}
    </div>
  );

  const onTooltipHandle = (index, item) => {
    setVendorName(item?.vendorname);
    setActiveButton(index);
    setShowTooltip(true);
  };
  const onContainerClick = () => {
    if (showTooltip) {
      setShowTooltip(false);
    }
  };
  const info = (
    <div className="info-tooltip-content">
      <ul>
        <li>
          Until all the colors for common size are approved, it will not be
          considered as “Approved” in Total Size.
        </li>
        <li>
          Until all the sizes for common color are approved, it will not be
          considered as “Approved” in Total Colors.
        </li>
      </ul>
    </div>
  );
  const text = (
    <div overlayClassName={"status-tooltip"}>
      <div className="tooltipbtn-container">
        <p className="tooltip-btn" onClick={onSampleTrackerHandle}>
          Edit Sample Tracker
        </p>
      </div>
      <div className="tooltip-table">
        <Table
          columns={columns}
          dataSource={data}
          // expandedRowRender={expandedRowRender}
          // expandable={{
          //   expandedRowKeys: data.map((item) => item.key),
          //   defaultExpandAllRows: true,
          // }}
        />
      </div>
      <div className="tooltipbtn-container">
        <p className="tooltip-close-btn" onClick={onContainerClick}>
          Close
        </p>
      </div>
    </div>
  );

  const onDesignChangeWIP = (e) => {
    dispatch(
      dashboardStatusUpdate({
        ...statusUpdatedData,
        wip_master_techpack: {
          ...statusUpdatedData.wip_master_techpack,
          design_document_status: e.target.value,
        },
      })
    );
    const apiData = {
      style: styleDetailsDashboard?.style,
      season: styleDetailsDashboard?.season,
      season_id: styleDetailsDashboard?.season_id,
      design_status: e.target.value,
      pd_status: statusUpdatedData?.wip_master_techpack?.pd_document_status,
      qa_status: statusUpdatedData?.wip_master_techpack?.qa_document_status,
    };
    dispatch(dashboardStatusSaver(apiData));
  };
  const onPdChangeWIP = (e) => {
    dispatch(
      dashboardStatusUpdate({
        ...statusUpdatedData,
        wip_master_techpack: {
          ...statusUpdatedData.wip_master_techpack,
          pd_document_status: e.target.value,
        },
      })
    );
    const apiData = {
      style: styleDetailsDashboard?.style,
      season: styleDetailsDashboard?.season,
      season_id: styleDetailsDashboard?.season_id,
      design_status:
        statusUpdatedData?.wip_master_techpack?.design_document_status,
      pd_status: e.target.value,
      qa_status: statusUpdatedData?.wip_master_techpack?.qa_document_status,
    };
    dispatch(dashboardStatusSaver(apiData));
  };
  const onQaChangeWIP = (e) => {
    dispatch(
      dashboardStatusUpdate({
        ...statusUpdatedData,
        wip_master_techpack: {
          ...statusUpdatedData.wip_master_techpack,
          qa_document_status: e.target.value,
        },
      })
    );
    const apiData = {
      style: styleDetailsDashboard?.style,
      season: styleDetailsDashboard?.season,
      season_id: styleDetailsDashboard?.season_id,
      design_status:
        statusUpdatedData?.wip_master_techpack?.design_document_status,
      pd_status: statusUpdatedData?.wip_master_techpack?.pd_document_status,
      qa_status: e.target.value,
    };
    dispatch(dashboardStatusSaver(apiData));
  };

  const onDesignChangeVerified = (e) => {
    console.log("statusUpdatedData", statusUpdatedData);
    dispatch(
      dashboardStatusUpdate({
        ...statusUpdatedData,
        verified_master_techpack: {
          ...statusUpdatedData.verified_master_techpack,
          design_document_status: e.target.value,
        },
      })
    );
    const apiData = {
      style: styleDetailsDashboard?.style,
      season: styleDetailsDashboard?.season,
      season_id: styleDetailsDashboard?.season_id,
      design_verified: e.target.value,
      pd_verified:
        statusUpdatedData?.verified_master_techpack?.pd_document_status,
      qa_verified:
        statusUpdatedData?.verified_master_techpack?.qa_document_status,
    };
    dispatch(dashboardStatusSaver(apiData));
  };
  const onPdChangeVerified = (e) => {
    dispatch(
      dashboardStatusUpdate({
        ...statusUpdatedData,
        verified_master_techpack: {
          ...statusUpdatedData.verified_master_techpack,
          pd_document_status: e.target.value,
        },
      })
    );
    const apiData = {
      style: styleDetailsDashboard?.style,
      season: styleDetailsDashboard?.season,
      season_id: styleDetailsDashboard?.season_id,
      design_verified:
        statusUpdatedData?.verified_master_techpack?.design_document_status,
      pd_verified: e.target.value,
      qa_verified:
        statusUpdatedData?.verified_master_techpack?.qa_document_status,
    };
    dispatch(dashboardStatusSaver(apiData));
  };
  const onQaChangeVerified = (e) => {
    dispatch(
      dashboardStatusUpdate({
        ...statusUpdatedData,
        verified_master_techpack: {
          ...statusUpdatedData.verified_master_techpack,
          qa_document_status: e.target.value,
        },
      })
    );
    const apiData = {
      style: styleDetailsDashboard?.style,
      season: styleDetailsDashboard?.season,
      season_id: styleDetailsDashboard?.season_id,
      design_verified:
        statusUpdatedData?.verified_master_techpack?.design_document_status,
      pd_verified:
        statusUpdatedData?.verified_master_techpack?.pd_document_status,
      qa_verified: e.target.value,
    };
    dispatch(dashboardStatusSaver(apiData));
  };

  const onDroaChecked = (e, data) => {
    console.log("datachecked", e.target.checked, data);
    setDataForDROA(data);
    if (e.target.checked == true) {
      setIsModalOpen(true);
      setChecked(1);
    } else if (e.target.checked == false) {
      setIsModalOpen(true);
      setChecked(0);
    } else {
      setIsModalOpen(false);
      setChecked(0);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setisRoaModalOpen(false);
    setisDroaReceivedModalOpen(false);
    dispatch(closeEmailModal(false));
  };

  let formData = new FormData();
  formData.append("style", statusUpdatedData?.style);
  formData.append("factory", vendorNameForEmail);
  formData.append("season", statusUpdatedData?.season);
  formData.append("brand", statusUpdatedData?.brand);
  formData.append("request_notes", textNote);
  if (file && Array.isArray(file)) {
    file.forEach((item) => {
      formData.append("files", item || "");
    });
  }
  formData.append("tpstatus", radioButtonValue);
  formData.append("attach_qav", qavButtonValue);
  formData.append("add_cc", ccList);

  const onEmailSendHandle = () => {
    dispatch(sendEmail(formData));
  };

  const onEmailSendClick = (data) => {
    localStorage.setItem("vendorName", data?.vendorname);
    dispatch(closeEmailModal(true));
    dispatch(
      getSavedEmail(
        statusUpdatedData?.style,
        data?.vendorname,
        styleDetailsDashboard?.season
      )
    );
    setVendorNameForEmail(data?.vendorname);
    setCCValue("");
    setCCList([]);
    console.log("e,data", data, statusUpdatedData);
  };

  const onSaveEmail = () => {
    formData.delete("brand");
    dispatch(
      SaveEmailDetails(
        formData,
        statusUpdatedData?.style,
        vendorNameForEmail,
        styleDetailsDashboard?.season
      )
    );
  };

  const onSaveHandle = () => {
    setIsModalOpen(false);
    dispatch(
      droaStatusSave(
        singleRowDataFromDashboard.style,
        dataFotDROA.vendorname,
        checked,
        singleRowDataFromDashboard,
        styleDetailsDashboard?.season
      )
    );
  };

  const onRoaReceived = (e, index, data) => {
    setDataForDROA(data);
    if (e.target.checked == true) {
      setRoaReceived(1);
      setisRoaModalOpen(true);
    } else if (e.target.checked == false) {
      setRoaReceived(0);
      setisRoaModalOpen(true);
    } else {
      setRoaReceived(0);
      setisRoaModalOpen(false);
    }
  };

  const onRoaSaveHandle = () => {
    setisRoaModalOpen(false);
    console.log("on Roa Received", dataFotDROA);
    dispatch(
      roaStatusSave(
        {
          style: singleRowDataFromDashboard.style,
          factory: dataFotDROA.vendorname,
          season: singleRowDataFromDashboard?.season,
          roa_received: `${roaReceived}`,
        },
        singleRowDataFromDashboard
      )
    );
  };
  const onDroaReceived = (e, index, data) => {
    setDataForDROA(data);
    if (e.target.checked == true) {
      setDroaReceived(1);
      setisDroaReceivedModalOpen(true);
    } else if (e.target.checked == false) {
      setDroaReceived(0);
      setisDroaReceivedModalOpen(true);
    } else {
      setDroaReceived(0);
      setisDroaReceivedModalOpen(false);
    }
  };
  const onDroaSaveHandle = () => {
    setisDroaReceivedModalOpen(false);
    console.log("on Roa Received", dataFotDROA);
    dispatch(
      roaStatusSave(
        {
          style: singleRowDataFromDashboard.style,
          factory: dataFotDROA.vendorname,
          season: singleRowDataFromDashboard?.season,
          droa_received: `${DroaReceived}`,
        },
        singleRowDataFromDashboard
      )
    );
  };

  const onStatusContainer = (style, vendor) => {
    localStorage.setItem(
      "dataForEmailStatus",
      JSON.stringify({
        style,
        vendor,
      })
    );
    console.log("here comes the data:", data?.vendorname);
    // dispatch(emailStatusUpdate(style, vendor));
  };
  const onDesignofWipChange = (e, vendor) => {
    console.log("e,vendore,vendor", e, vendor, statusUpdatedData?.vendors);
    const dataToMap = statusUpdatedData?.vendors?.find(
      (item) => item?.vendorname === vendor
    );
    console.log("dataToMap", dataToMap?.wip_vendor_techpack);
    const vendorNameToUpdate = vendor;
    dispatch(
      dashboardStatusUpdate({
        ...statusUpdatedData,
        vendors: statusUpdatedData?.vendors?.map((vendor) => {
          if (
            vendor.vendorname === vendorNameToUpdate &&
            vendor.wip_vendor_techpack
          ) {
            return {
              ...vendor,
              wip_vendor_techpack: {
                ...vendor.wip_vendor_techpack,
                design_document_status: e.target.value,
              },
            };
          }
          return vendor;
        }),
      })
    );
    const apiData = {
      style: styleDetailsDashboard?.style,
      factory: vendor,
      season: styleDetailsDashboard?.season,
      pd_status: dataToMap?.wip_vendor_techpack?.pd_document_status,
      qa_status: dataToMap?.wip_vendor_techpack?.qa_document_status,
      design_status: e.target.value,
    };
    dispatch(tpDropdownStatusUpdate(apiData));
  };

  const onPdofWipChange = (e, vendor) => {
    const vendorNameToUpdate = vendor;
    const dataToMap = statusUpdatedData?.vendors?.find(
      (item) => item?.vendorname === vendor
    );
    dispatch(
      dashboardStatusUpdate({
        ...statusUpdatedData,
        vendors: statusUpdatedData.vendors.map((vendor) => {
          if (
            vendor.vendorname === vendorNameToUpdate &&
            vendor.wip_vendor_techpack
          ) {
            return {
              ...vendor,
              wip_vendor_techpack: {
                ...vendor.wip_vendor_techpack,
                pd_document_status: e.target.value,
              },
            };
          }
          return vendor;
        }),
      })
    );
    const apiData = {
      style: styleDetailsDashboard?.style,
      factory: vendor,
      season: styleDetailsDashboard?.season,
      pd_status: e.target.value,
      qa_status: dataToMap?.wip_vendor_techpack?.qa_document_status,
      design_status: dataToMap?.wip_vendor_techpack?.design_document_status,
    };
    dispatch(tpDropdownStatusUpdate(apiData));
  };
  const onQaofWipChange = (e, vendor) => {
    console.log("e,vendor", e, vendor);
    const vendorNameToUpdate = vendor;
    const dataToMap = statusUpdatedData?.vendors?.find(
      (item) => item?.vendorname === vendor
    );
    dispatch(
      dashboardStatusUpdate({
        ...statusUpdatedData,
        vendors: statusUpdatedData.vendors.map((vendor) => {
          if (
            vendor.vendorname === vendorNameToUpdate &&
            vendor.wip_vendor_techpack
          ) {
            return {
              ...vendor,
              wip_vendor_techpack: {
                ...vendor.wip_vendor_techpack,
                qa_document_status: e.target.value,
              },
            };
          }
          return vendor;
        }),
      })
    );
    const apiData = {
      style: styleDetailsDashboard?.style,
      factory: vendor,
      season: styleDetailsDashboard?.season,
      pd_status: dataToMap?.wip_vendor_techpack?.pd_document_status,
      qa_status: e.target.value,
      design_status: dataToMap?.wip_vendor_techpack?.design_document_status,
    };
    dispatch(tpDropdownStatusUpdate(apiData));
  };
  const onDesignofVerifiedChange = (e, vendor) => {
    console.log("onDesignofWipChange", e.target.value, vendor);
    const vendorNameToUpdate = vendor;
    const dataToMap = statusUpdatedData?.vendors?.find(
      (item) => item?.vendorname === vendor
    );
    console.log("dataToMap", dataToMap);
    dispatch(
      dashboardStatusUpdate({
        ...statusUpdatedData,
        vendors: statusUpdatedData.vendors.map((vendor) => {
          if (
            vendor.vendorname === vendorNameToUpdate &&
            vendor.verified_vendor_techpack
          ) {
            return {
              ...vendor,
              verified_vendor_techpack: {
                ...vendor.verified_vendor_techpack,
                design_document_status: e.target.value,
              },
            };
          }
          return vendor;
        }),
      })
    );
    const apiData = {
      style: styleDetailsDashboard?.style,
      factory: vendor,
      season: styleDetailsDashboard?.season,
      pd_verified: dataToMap?.verified_vendor_techpack?.pd_document_status,
      qa_verified: dataToMap?.verified_vendor_techpack?.qa_document_status,
      design_verified: e.target.value,
    };
    dispatch(tpDropdownStatusUpdate(apiData));
  };
  const onPdofVerifiedChange = (e, vendor) => {
    console.log("onDesignofWipChange", e.target.value);
    const vendorNameToUpdate = vendor;
    const dataToMap = statusUpdatedData?.vendors?.find(
      (item) => item?.vendorname === vendor
    );
    dispatch(
      dashboardStatusUpdate({
        ...statusUpdatedData,
        vendors: statusUpdatedData.vendors.map((vendor) => {
          if (
            vendor.vendorname === vendorNameToUpdate &&
            vendor.verified_vendor_techpack
          ) {
            return {
              ...vendor,
              verified_vendor_techpack: {
                ...vendor.verified_vendor_techpack,
                pd_document_status: e.target.value,
              },
            };
          }
          return vendor;
        }),
      })
    );
    const apiData = {
      style: styleDetailsDashboard?.style,
      factory: vendor,
      season: styleDetailsDashboard?.season,
      pd_verified: e.target.value,
      qa_verified: dataToMap?.verified_vendor_techpack?.qa_document_status,
      design_verified:
        dataToMap?.verified_vendor_techpack?.design_document_status,
    };
    dispatch(tpDropdownStatusUpdate(apiData));
  };
  const onQaofVerifiedChange = (e, vendor) => {
    console.log("onDesignofWipChange", e.target.value);
    const vendorNameToUpdate = vendor;
    const dataToMap = statusUpdatedData?.vendors?.find(
      (item) => item?.vendorname === vendor
    );
    dispatch(
      dashboardStatusUpdate({
        ...statusUpdatedData,
        vendors: statusUpdatedData.vendors.map((vendor) => {
          if (
            vendor.vendorname === vendorNameToUpdate &&
            vendor.verified_vendor_techpack
          ) {
            return {
              ...vendor,
              verified_vendor_techpack: {
                ...vendor.verified_vendor_techpack,
                qa_document_status: e.target.value,
              },
            };
          }
          return vendor;
        }),
      })
    );
    const apiData = {
      style: styleDetailsDashboard?.style,
      factory: vendor,
      season: styleDetailsDashboard?.season,
      pd_verified: dataToMap?.verified_vendor_techpack?.pd_document_status,
      qa_verified: e.target.value,
      design_verified:
        dataToMap?.verified_vendor_techpack?.design_document_status,
    };
    dispatch(tpDropdownStatusUpdate(apiData));
  };
  const changeFileHandler = (e) => {
    const newFiles = Array.from(e.target.files);

    newFiles.forEach((newFile) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64File = reader.result;
        setDashboardLocalData((prev) => {
          const updatedAttachOtherFiles = [
            ...(prev.attach_other_files || []),
            { name: newFile.name, content: base64File },
          ];
          return {
            ...prev,
            attach_other_files: updatedAttachOtherFiles,
          };
        });
      };

      reader.readAsDataURL(newFile);
    });
    setFile([...file, e.target.files[0]]);
    setFileName([...fileName, { file_name: e.target.files[0].name }]);
  };
  const onDeleteClick = (item, index) => {
    const fileForApi = fileName.filter((item, i) => i == index);
    setFileName(fileName.filter((item, i) => i !== index));
    setFile(file.filter((item, i) => i !== index));
    const apiData = {
      style: statusUpdatedData?.style,
      factory: factory,
      filename: fileForApi?.[0]?.file_name.toString(),
    };
    dispatch(DeleteEmailDetails(apiData));
  };

  const onDownloadClick = (url, index) => {
    // const filterFile = emailData?.file_name?.filter(
    //   (fileName) => fileName === item
    // );
    // console.log("filterFile", filterFile);
    if (url) {
      saveAs(url);
      toast.success("File downloaded successfully");
    } else toast.error("failed to downloaded File");
    // dispatch(DownloadEmail(statusUpdatedData?.style, factory, item));
  };
  const onTpStatusChange = (e) => {
    setRadioButtonValue(e.target.value);
    setDashboardLocalData({
      ...dashboardLocalData,
      attach_tech_pack: e.target.value,
    });
  };
  const onQavStatusChange = (e) => {
    setQavButtonValue(e.target.value);
    setDashboardLocalData({
      ...dashboardLocalData,
      attach_QAV: e.target.value,
    });
  };
  const onTextNoteChange = (e) => {
    setTextNote(e.target.value);
    setDashboardLocalData({
      ...dashboardLocalData,
      request_notes: e.target.value,
    });
  };

  const onCCHandle = (e) => {
    setCCValue(e.target.value);
    setDashboardLocalData({
      ...dashboardLocalData,
      add_cc_to_email: e.target.value,
    });
  };

  const onAddCCHandle = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(ccValue)) {
      toast.error("Please enter a valid Email");
      return;
    }

    if (ccList.includes(ccValue)) {
      toast.error("This Email is already added");
      return;
    }
    setCCList([...ccList, ccValue]);
    setCCValue("");
  };

  const onRemoveCCHandle = (index) => {
    const ccEmailsFilter = ccList.filter((_, i) => i !== index);
    setCCList(ccEmailsFilter);
  };
  return (
    <>
      <div className="accordion-wrapper">
        <div className="accordion-section-one">
          <div className="section-one">
            <span className="info-tooltip">
              <Tooltip
                overlayInnerStyle={{
                  height: "fit-content",
                  width: "340px",
                  padding: "20px",
                  color: "black",
                  marginLeft: "10px",
                }}
                color={"white"}
                placement={"bottom"}
                trigger="hover"
                title={info}
              >
                <img className="info-icon" src={Info} alt="Info-icon" />
              </Tooltip>
            </span>
            <div className="accordion-section-one-column-one">
              <div className="accordion-header-section1">
                <p>
                  <span className="header1-section1">Total Line Items : </span>
                  <span className="header12-section1">
                    {styleDetailsDashboard?.total_line_items}
                  </span>
                </p>
              </div>
              <div className="accordion-btn-section">
                <p className="accordion-sizechart">
                  <span className="total-heading">Total Colors : </span>
                  <span className="tooltip-colorlist">
                    {styleDetailsDashboard?.total_colors}
                  </span>
                </p>
                <div className="accordion-btns">
                  <p className="accordiion-btn1">
                    <span>{styleDetailsDashboard?.approved_colors}</span>{" "}
                    Approved
                  </p>
                  <p className="accordiion-btn2">
                    <span>{styleDetailsDashboard?.pending_colors}</span> Pending
                  </p>
                </div>
              </div>
              <div className="accordion-btn-section">
                <p className="accordion-sizechart">
                  <span className="total-heading">Total Size : </span>
                  {styleDetailsDashboard?.total_sizes}
                </p>
                <div className="accordion-btns">
                  <p className="accordiion-btn1">
                    <span>{styleDetailsDashboard?.approved_sizes}</span>{" "}
                    Approved
                  </p>
                  <p className="accordiion-btn2">
                    <span>{styleDetailsDashboard?.pending_sizes}</span> Pending
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-header-section2">
              <p className="header2-section1" onClick={onSampleTrackerHandle}>
                Sample Tracker - View
              </p>
              <a href={styleDetailsDashboard.TechpackCreation} target="_blank">
                <p className="header1-section2">TechPack - View</p>
              </a>
              <a href={styleDetailsDashboard.line_plan_url} target="_blank">
                <p className="header1-section2">Line Plan - View</p>
              </a>
            </div>
          </div>
          <div className="section-two">
            <div className="master-tech-and-lineplan">
              <p className="header2-section2">Master Tech Pack - WIP</p>
              <a href={styleDetailsDashboard.line_plan_url} target="_blank">
                {/* <p className="header1-section2">Line Plan - View</p> */}
              </a>
            </div>
            <div className="accordion-section2-table">
              <div className="table-radiogroup1">
                <span className="table-head">Design</span>
                <Radio.Group
                  size="small"
                  onChange={onDesignChangeWIP}
                  className="table-radiogroup1"
                  value={
                    statusUpdatedData?.wip_master_techpack
                      ?.design_document_status
                  }
                >
                  <Radio className="table-radio" name="yes" value={false}>
                    No
                  </Radio>
                  <Radio name="no" value={true}>
                    Yes
                  </Radio>
                </Radio.Group>
              </div>
              <div className="table-radiogroup1">
                <span className="table-head">PD</span>

                <Radio.Group
                  onChange={onPdChangeWIP}
                  className="table-radiogroup1"
                  value={
                    statusUpdatedData?.wip_master_techpack?.pd_document_status
                  }
                >
                  <Radio className="table-radio" name="yes" value={false}>
                    No
                  </Radio>
                  <Radio name="no" value={true}>
                    Yes
                  </Radio>
                </Radio.Group>
              </div>
              <div className="table-radiogroup1">
                <span className="table-head">QA</span>
                <Radio.Group
                  onChange={onQaChangeWIP}
                  className="table-radiogroup1"
                  value={
                    statusUpdatedData?.wip_master_techpack?.qa_document_status
                  }
                >
                  <Radio className="table-radio" name="yes" value={false}>
                    No
                  </Radio>
                  <Radio name="no" value={true}>
                    Yes
                  </Radio>
                </Radio.Group>
              </div>
            </div>
            <div className="master-tech-and-lineplan">
              <p className="header2-section2">Master Tech Pack - VERIFIED</p>
              <a href={styleDetailsDashboard.line_plan_url} target="_blank">
                {/* <p className="header1-section2">Line Plan - View</p> */}
              </a>
            </div>
            <div className="accordion-section2-table">
              <div className="table-radiogroup1">
                <span className="table-head">Design</span>
                <Radio.Group
                  size="small"
                  onChange={onDesignChangeVerified}
                  className="table-radiogroup1"
                  value={
                    statusUpdatedData?.verified_master_techpack
                      ?.design_document_status
                  }
                >
                  <Radio className="table-radio" name="yes" value={false}>
                    No
                  </Radio>
                  <Radio name="no" value={true}>
                    Yes
                  </Radio>
                </Radio.Group>
              </div>
              <div className="table-radiogroup1">
                <span className="table-head">PD</span>

                <Radio.Group
                  onChange={onPdChangeVerified}
                  className="table-radiogroup1"
                  value={
                    statusUpdatedData?.verified_master_techpack
                      ?.pd_document_status
                  }
                >
                  <Radio className="table-radio" name="yes" value={false}>
                    No
                  </Radio>
                  <Radio name="no" value={true}>
                    Yes
                  </Radio>
                </Radio.Group>
              </div>
              <div className="table-radiogroup1">
                <span className="table-head">QA</span>
                <Radio.Group
                  onChange={onQaChangeVerified}
                  className="table-radiogroup1"
                  value={
                    statusUpdatedData?.verified_master_techpack
                      ?.qa_document_status
                  }
                >
                  <Radio className="table-radio" name="yes" value={false}>
                    No
                  </Radio>
                  <Radio name="no" value={true}>
                    Yes
                  </Radio>
                </Radio.Group>
              </div>
            </div>
            {/* <div className="table-radiogroup3">
              <p className="header1-section2">Note:</p>
              <p>
                Lorem ipsum dolor sit amet. Est alias doloribus sit omnis
                galisum sed deserunt rerum. Aut velit illo non reprehenderit
                amet eum molestias inventore sed quis perspiciatis ut autem
                consectetur.
              </p>
            </div> */}
          </div>
        </div>
        {/* third section */}
        <div className="accordion-section-three">
          <h3>Sample Request</h3>
          <div className="section-three">
            {statusUpdatedData?.vendors?.map((item, index) => {
              console.log("styledatalog", item, index);
              return (
                <div className="section-box1">
                  <div className="section-three-box1">
                    <div className="section-header">
                      <h4 className="box1-title">{item?.vendorname}</h4>
                      <p
                        className="send-mail-btn"
                        onClick={() => onEmailSendClick(item)}
                      >
                        Send Email
                      </p>
                    </div>
                    <p className="dotted-divider"></p>
                    <div className="vendor-tp-container">
                      <div className="vendor-techpack-main">
                        {item?.wip_vendor_techpack ? (
                          <div>
                            {/* <p className="vendor-tp-heading">
                          Vendor Tech Pack -{" "}
                          <span className="vendor-tp-wip">WIP</span>
                        </p> */}
                            <div className="accordion-section2-table">
                              {/* <div className="table-radiogroup2">
                            <span className="table-head">Design</span>
                            <Radio.Group
                              size="small"
                              onChange={(e) => onDesignofWipChange(e,item?.vendorname)}
                              className="table-radiogroup2"
                              value={item?.wip_vendor_techpack?.design_document_status}
                            >
                              <Radio
                                className="table-radio"
                                name="yes"
                                value={false}
                              >
                                No
                              </Radio>
                              <Radio name="no" value={true}>
                                Yes
                              </Radio>
                            </Radio.Group>
                          </div> */}
                              {/* <div className="table-radiogroup2">
                            <span className="table-head">PD</span>
                            <Radio.Group
                              onChange={(e) => onPdofWipChange(e,item?.vendorname)}
                              className="table-radiogroup2"
                              value={item?.wip_vendor_techpack?.pd_document_status}
                            >
                              <Radio
                                className="table-radio"
                                name="yes"
                                value={false}
                              >
                                No
                              </Radio>
                              <Radio name="no" value={true}>
                                Yes
                              </Radio>
                            </Radio.Group>
                          </div> */}
                              {/* <div className="table-radiogroup2">
                            <span className="table-head">QA</span>
                            <Radio.Group
                              onChange={(e) => onQaofWipChange(e,item?.vendorname)}
                              className="table-radiogroup2"
                              value={item?.wip_vendor_techpack?.qa_document_status}
                            >
                              <Radio
                                className="table-radio"
                                name="yes"
                                value={false}
                              >
                                No
                              </Radio>
                              <Radio name="no" value={true}>
                                Yes
                              </Radio>
                            </Radio.Group>
                          </div> */}
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        {item?.verified_vendor_techpack ? (
                          <div>
                            <p className="vendor-tp-heading">
                              Vendor Tech Pack -{" "}
                              <span className="vendor-tp-wip">VERIFIED</span>
                            </p>
                            <div className="accordion-section2-table">
                              <div className="table-radiogroup2">
                                <span className="table-head">Design</span>

                                <Radio.Group
                                  size="small"
                                  onChange={(e) =>
                                    onDesignofVerifiedChange(
                                      e,
                                      item?.vendorname
                                    )
                                  }
                                  className="table-radiogroup2"
                                  value={
                                    item?.verified_vendor_techpack
                                      ?.design_document_status
                                  }
                                >
                                  <Radio
                                    className="table-radio"
                                    name="yes"
                                    value={false}
                                  >
                                    No
                                  </Radio>
                                  <Radio name="no" value={true}>
                                    Yes
                                  </Radio>
                                </Radio.Group>
                              </div>
                              <div className="table-radiogroup2">
                                <span className="table-head">PD</span>

                                <Radio.Group
                                  onChange={(e) =>
                                    onPdofVerifiedChange(e, item?.vendorname)
                                  }
                                  className="table-radiogroup2"
                                  value={
                                    item?.verified_vendor_techpack
                                      ?.pd_document_status
                                  }
                                >
                                  <Radio
                                    className="table-radio"
                                    name="yes"
                                    value={false}
                                  >
                                    No
                                  </Radio>
                                  <Radio name="no" value={true}>
                                    Yes
                                  </Radio>
                                </Radio.Group>
                              </div>
                              <div className="table-radiogroup2">
                                <span className="table-head">QA</span>

                                <Radio.Group
                                  onChange={(e) =>
                                    onQaofVerifiedChange(e, item?.vendorname)
                                  }
                                  className="table-radiogroup2"
                                  value={
                                    item?.verified_vendor_techpack
                                      ?.qa_document_status
                                  }
                                >
                                  <Radio
                                    className="table-radio"
                                    name="yes"
                                    value={false}
                                  >
                                    No
                                  </Radio>
                                  <Radio name="no" value={true}>
                                    Yes
                                  </Radio>
                                </Radio.Group>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      {item?.droa_needed == true ? (
                        <div className="checkbox-container">
                          <Checkbox
                            checked={item?.droa_checked}
                            onChange={(e) => onDroaChecked(e, item)}
                          >
                            Is a new DROA needed?
                          </Checkbox>
                        </div>
                      ) : (
                        <></>
                      )}
                      {item?.roa_needed == true ? (
                        <div className="checkbox-container">
                          <Checkbox
                            checked={item?.roa_received}
                            onChange={(e) => onRoaReceived(e, index, item)}
                          >
                            Is ROA received?
                          </Checkbox>
                        </div>
                      ) : (
                        <></>
                      )}
                      {item?.droa_received_needed == true ? (
                        <div className="checkbox-container">
                          <Checkbox
                            checked={item?.droa_received}
                            onChange={(e) => onDroaReceived(e, index, item)}
                          >
                            Is DROA received?
                          </Checkbox>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="vendor-color-main-container">
                      <p>
                        <span className="vendor-total-colors">
                          Total Colors :{" "}
                        </span>
                        <span className="vendor-total-color-count">
                          {item?.total_colors}
                        </span>
                      </p>
                      <div className="vendor-approved-button-container">
                        <p className="vendor-approved">
                          <span>{item?.approved_colors}</span> Approved
                        </p>
                        <p className="vendor-pending">
                          <span>{item?.pending_colors}</span> Pending
                        </p>
                      </div>
                    </div>
                    <div className="vendor-color-main-container">
                      <p>
                        <span className="vendor-total-colors">
                          Total Size :{" "}
                        </span>
                        <span className="vendor-total-color-count">
                          {item?.total_sizes}
                        </span>
                      </p>
                      <div className="vendor-approved-button-container">
                        <p className="vendor-approved">
                          <span>{item?.approved_sizes}</span> Approved
                        </p>
                        <p className="vendor-pending">
                          <span>{item?.pending_sizes}</span> Pending
                        </p>
                      </div>
                    </div>

                    <div className="section3-box1">
                      {/* {item?.colors?.colorlist?.map((dataOfColor) => {
                        return (
                          <p className="box1-desc">
                            <span className="box1-description">Colors : </span>
                            {dataOfColor}
                          </p>
                        );
                      })} */}
                      <div className="srt-status-container">
                        {activeButton === index && (
                          <Tooltip
                            overlayInnerStyle={{
                              height: "fit-content",
                              width: "685px",
                              padding: "20px 20px",
                              marginBottom: "60px",
                              marginLeft: "-134px",
                            }}
                            color={"white"}
                            placement={"top"}
                            open={showTooltip}
                            trigger="click"
                            title={text}
                          ></Tooltip>
                        )}
                        <p
                          className="box1-btn"
                          onClick={() => onTooltipHandle(index, item)}
                        >
                          SRT Status
                        </p>
                      </div>
                      <p
                        className="box1-btn"
                        id="go-to-srf"
                        onClick={() => onGoToSRFHandle(item)}
                      >
                        Go to Sample Request Form
                      </p>
                    </div>
                  </div>
                  {/* <Link to="../statusupdate" target="_blank"> */}
                  <div
                    onClick={() =>
                      onStatusContainer(
                        styleDetailsDashboard?.style,
                        item?.vendorname
                      )
                    }
                    className="status-wrapper"
                  >
                    <span className="status-container">
                      <div
                        className={
                          item?.overall_status === "In Development"
                            ? "development-btns"
                            : item?.overall_status === "Product Ready"
                            ? "launch-btns"
                            : item?.overall_status === "Dropped"
                            ? "dropped-btns"
                            : item?.overall_status === "Execute"
                            ? "adopted-btns"
                            : item?.overall_status === "TP Verified"
                            ? "verified-btns"
                            : ""
                        }
                      >
                        {item?.overall_status}
                      </div>
                    </span>
                  </div>
                  {/* </Link> */}
                </div>
              );
            })}
          </div>
        </div>
        <Modal
          title="Do you want to perform this action?"
          open={isModalOpen}
          okText={"save"}
          onCancel={handleCancel}
        >
          <div className="dashboard-modal-buttons">
            <Button className="modal-yes-button" onClick={onSaveHandle}>
              Yes
            </Button>
            <Button className="modal-cancel-btn" onClick={handleCancel}>
              No
            </Button>
          </div>
        </Modal>
        <Modal
          title="Do you want to perform this action?"
          open={isRoaModalOpen}
          okText={"save"}
          onCancel={handleCancel}
        >
          <div className="dashboard-modal-buttons">
            <Button className="modal-yes-button" onClick={onRoaSaveHandle}>
              Yes
            </Button>
            <Button className="modal-cancel-btn" onClick={handleCancel}>
              No
            </Button>
          </div>
        </Modal>
        <Modal
          title="Do you want to perform this action?"
          open={isDroaReceivedModalOpen}
          okText={"save"}
          onCancel={handleCancel}
        >
          <div className="dashboard-modal-buttons">
            <Button className="modal-yes-button" onClick={onDroaSaveHandle}>
              Yes
            </Button>
            <Button className="modal-cancel-btn" onClick={handleCancel}>
              No
            </Button>
          </div>
        </Modal>

        <Modal
          title="Email Confirmation"
          open={emailModalToggle}
          onCancel={handleCancel}
        >
          <div>
            <div className="email-main-container">
              <div className="email-container-one">
                <p className="attach-tech-name">Attach Tech pack? :</p>
                <p className="attach-qav-name">Are you attaching a QAV? :</p>
                <p className="attach-qav-name">Add CC? :</p>
                <p className="attach-files-name">Attach Other Files? :</p>
                <p className="request-note-name">Request Notes :</p>
              </div>
              <div className="email-container-two">
                <p className="bottom-section-radio">
                  <span>
                    <Radio.Group
                      onChange={onTpStatusChange}
                      value={radioButtonValue}
                    >
                      <Radio name="1" value={1}>
                        Yes
                      </Radio>
                      <Radio name="0" value={0}>
                        No
                      </Radio>
                    </Radio.Group>
                  </span>
                </p>
                <p className="bottom-qav-radio">
                  <span>
                    <Radio.Group
                      onChange={onQavStatusChange}
                      value={qavButtonValue}
                    >
                      <Radio name="1" value={1}>
                        Yes
                      </Radio>
                      <Radio name="0" value={0}>
                        No
                      </Radio>
                    </Radio.Group>
                  </span>
                </p>
                <div className="add-cc-list-button-container">
                  <div className="add-cc-main-container">
                    {ccList &&
                      ccList?.map((item, index) => {
                        return (
                          <div className="add-cc-section-container">
                            <p>{item}</p>
                            <Tooltip
                              overlayInnerStyle={{
                                color: "black",
                              }}
                              color={"white"}
                              placement={"top"}
                              trigger="hover"
                              title={"Remove"}
                            >
                              <CloseOutlined
                                twoToneColor="#000000"
                                onClick={() => onRemoveCCHandle(index)}
                              />
                            </Tooltip>
                          </div>
                        );
                      })}
                  </div>
                  <div className="add-cc-container">
                    <InputField
                      placeholder={"Email address"}
                      onChange={onCCHandle}
                      value={ccValue}
                    />
                    <Button children={"Add"} onClick={onAddCCHandle} />
                  </div>
                </div>

                <p className="upload-section">
                  <span>
                    <Button
                      icon={<UploadOutlined />}
                      onClick={() => fileRef.current.click()}
                    >
                      UPLOAD
                    </Button>
                    <input
                      type="file"
                      onChange={changeFileHandler}
                      accept="image/jpeg,image/png,image/jpg, application/pdf,application/.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      ref={fileRef}
                      hidden
                    />
                    <div className="document-name-container">
                      {fileName ? (
                        fileName?.map((item, index) => {
                          console.log("filenames", item);
                          return (
                            <div className="document-section-container">
                              <Tooltip
                                overlayInnerStyle={{
                                  color: "black",
                                }}
                                color={"white"}
                                placement={"top"}
                                trigger="hover"
                                title={"Download"}
                              >
                                <DownloadOutlined
                                  twoToneColor="#000000"
                                  onClick={(e) =>
                                    onDownloadClick(item?.file_url, index)
                                  }
                                />
                              </Tooltip>
                              <p>{item?.file_name}</p>
                              <Tooltip
                                overlayInnerStyle={{
                                  color: "black",
                                }}
                                color={"white"}
                                placement={"top"}
                                trigger="hover"
                                title={"Delete"}
                              >
                                <CloseOutlined
                                  twoToneColor="#000000"
                                  onClick={(e) => onDeleteClick(item, index)}
                                />
                              </Tooltip>
                            </div>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                  </span>
                </p>

                <span className="request-notes">
                  <TextArea
                    rows={4}
                    placeholder="Type something"
                    value={textNote}
                    onChange={onTextNoteChange}
                  />
                </span>
              </div>
              {/* <p>Do you want to send email for the style: <b>{statusUpdatedData?.style}</b>, brand: <b>{statusUpdatedData?.brand}</b>, factory: <b>{vendorNameForEmail}</b>, Season: <b>{statusUpdatedData?.season}</b></p> */}
            </div>
            <div className="dashboard-modal-buttons">
              <Button className="modal-yes-button" onClick={onEmailSendHandle}>
                SEND
              </Button>
              <Button className="modal-cancel-btn" onClick={onSaveEmail}>
                SAVE
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};
export default AccordionContent;
