import React, { useState } from "react";
import { Button, DatePicker, Modal, Tooltip } from "antd";
import dayjs from "dayjs";
import "./DateSentRec.css";
import { useDispatch, useSelector } from "react-redux";
import { reqRecDate, srtDataSaver } from "../../../../Redux/actions/Srt/Srt";
import resetCalendar from "../../../../images/calendarReset.png";

const DateSentRec = ({
  createdDate,
  reqNo,
  dataOfReq,
  recieveDate,
  requestRecievedDate,
  itemData,
  salesSample
}) => {
  console.log("recieveDate", recieveDate);
  console.log("itemData", dataOfReq);
  const dispatch = useDispatch();
  const dateFormat = "MM/DD/YYYY";
  const isToday = dayjs().format(dateFormat);
  const [date, setDate] = useState(isToday);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const reqRecivedData = useSelector((state) => state?.Srt?.reqRecDateVar);

  const onDateChange = (date, dateString) => {
    dispatch(
      srtDataSaver(
        itemData?.style,
        itemData?.brand,
        itemData?.factory,
        itemData?.size,
        itemData?.season,
        itemData?.color_code,
        dataOfReq?.request_no,
        "",
        "",
        dateString,
        "",
        salesSample
      )
    );
    dispatch(reqRecDate({ ...reqRecivedData, request_recieved: dateString }));
    setDate(dateString);
  };

  const disabledDate = (current, receiveDate) => {
    // disable dates before the receiveDate + 3 weeks
    const currentDate = dayjs(createdDate);
    if (current < currentDate.startOf("day")) {
      return true;
    }
    // disable dates after 3 months from today
    //  const threeMonthsLater = currentDate.add(3, 'months');
    //  if (current > threeMonthsLater.endOf('day')) {
    //    return true;
    //  }
    return false;
  };

  const onResetClick = () => {
    setIsModalOpen(!isModalOpen);
  };

  const onContinue = () => {
    dispatch(
      srtDataSaver(
        itemData?.style,
        itemData?.brand,
        itemData?.factory,
        itemData?.size,
        itemData?.season,
        itemData?.color_code,
        dataOfReq?.request_no,
        "",
        "",
        null,
        dataOfReq?.QtyRcd == null ? "" : dataOfReq?.QtyRcd,
        salesSample
      )
    );
    setIsModalOpen(!isModalOpen);
  };
  const handleCancel = () => {
    setIsModalOpen(!isModalOpen);
  };
  const onDatePickerHandle = (reqNo, dataOfReq) => {
    dispatch(
      reqRecDate(
        reqRecivedData?.request_no === reqNo ? reqRecivedData : dataOfReq
      )
    );
  };
  console.log("reqRecivedData", reqRecivedData, dataOfReq);

  const datePickerFunction = () => {
    if (requestRecievedDate) {
      const dateObj = dayjs(requestRecievedDate);
      const formattedDate = dateObj.format("MM/DD/YYYY");
      return (
        <DatePicker
          allowClear={false}
          onClick={() => onDatePickerHandle(reqNo, dataOfReq)}
          defaultValue={dayjs(formattedDate, "MM/DD/YYYY")}
          disabledDate={disabledDate}
          disabled={
            !recieveDate ||
            recieveDate == " " ||
            recieveDate == "" ||
            recieveDate == null ||
            recieveDate == "null"
          }
          onChange={onDateChange}
          format="MM/DD/YYYY"
        />
      );
    } else {
      return (
        <DatePicker
          allowClear={false}
          onClick={() => onDatePickerHandle(reqNo, dataOfReq)}
          onChange={onDateChange}
          disabledDate={disabledDate}
          disabled={
            !recieveDate ||
            recieveDate == " " ||
            recieveDate == "" ||
            recieveDate == null ||
            recieveDate == "null"
          }
          format="MM/DD/YYYY"
        />
      );
    }
  };
  return (
    <div className="srt-table-data-three">
      <div className="srt-date-pickers">
        <p className="srt-date">{recieveDate}</p>
        <p className="srt-datepicker">
          {requestRecievedDate === "None" ||
          requestRecievedDate === "" ||
          requestRecievedDate === null ||
          requestRecievedDate === "null" ? (
            <DatePicker
              allowClear={false}
              onClick={() => onDatePickerHandle(reqNo, dataOfReq)}
              onChange={onDateChange}
              disabledDate={disabledDate}
              disabled={
                !recieveDate ||
                recieveDate == " " ||
                recieveDate == "" ||
                recieveDate == null ||
                recieveDate == "null"
              }
              format="MM/DD/YYYY"
            />
          ) : (
            <div className="date-picker-clear-container">
              {datePickerFunction()}
              <Tooltip placement="top" title={"Reset"}>
                <img
                  className="reset-icon"
                  onClick={onResetClick}
                  alt="reset-icon"
                  src={resetCalendar}
                />
              </Tooltip>
            </div>
          )}
        </p>
      </div>
      <Modal
        title="Do you want to Reset the Received Date?"
        open={isModalOpen}
        okText={"save"} onCancel={handleCancel}
      >
        <div className="dashboard-modal-buttons">
          <Button className="modal-yes-button" onClick={onContinue}>
            Yes
          </Button>
          <Button className="modal-cancel-btn" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default DateSentRec;
