import React, { useEffect, useState } from "react";
import "./SrtTabs.css";
import Dickies from "./Dickies/Dickies";
import { Tabs } from "antd";
import Cookies from "js-cookie";
import Saucony from "./Saucony/Saucony";
import Eddie from "./Eddie Bauer/Eddie";
import PdRedevelopment from "./PdRedevelopment/PdRedevelopment";
import {
  getSrtInitialList,
  getSrtUnsolicitedSamplesList,
  resetMassFilter,
  resetMassUnsolicitedFilter,
  resetSeasonFilter,
  resetSrtData,
  resetSrtSeasonFilter,
  selectUnselectSrtTableRows,
  srtEXportArray,
  srtbrandName,
} from "../../Redux/actions/Srt/Srt";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SalesSamples from "./SalesSamples/SalesSamples";
import UnsolicitedSamples from "./UnsolicitedSamples/UnsolicitedSamples";
const { TabPane } = Tabs;

const SrtTabs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const srtDataForApi = JSON.parse(localStorage.getItem("srtData"));
  // const [activeKey, setActiveKey] = useState(localStorage.getItem('srtActiveKey'))

  const path = location.pathname;

  if (path.includes("/sample-receipt-tracker/dickies")) {
    dispatch(srtbrandName("Dickies"));
    dispatch(
      getSrtInitialList(
        srtDataForApi?.brand ? srtDataForApi?.brand : "Dickies",
        srtDataForApi?.style ? srtDataForApi?.style : "",
        srtDataForApi?.factory ? srtDataForApi?.factory : "",
        srtDataForApi?.rejected_date ? srtDataForApi?.rejected_date : "",
        0,
        srtDataForApi?.season ? srtDataForApi?.season : ""
      )
    );
    dispatch(resetSrtData([]));
    dispatch(resetMassFilter());
    dispatch(resetSeasonFilter());
    dispatch(resetSrtSeasonFilter());
    dispatch(resetMassUnsolicitedFilter([]));
  } else if (path.includes("/sample-receipt-tracker/saucony")) {
    dispatch(srtbrandName("Saucony"));
    dispatch(
      getSrtInitialList(
        srtDataForApi?.brand ? srtDataForApi?.brand : "Saucony",
        srtDataForApi?.style ? srtDataForApi?.style : "",
        srtDataForApi?.factory ? srtDataForApi?.factory : "",
        srtDataForApi?.rejected_date ? srtDataForApi?.rejected_date : "",
        0,
        srtDataForApi?.season ? srtDataForApi?.season : ""
      )
    );
    dispatch(resetSrtData([]));
    dispatch(resetMassFilter());
    dispatch(resetSeasonFilter());
    dispatch(resetSrtSeasonFilter());
    dispatch(resetMassUnsolicitedFilter([]));
  } else if (path.includes("/sample-receipt-tracker/eddie-bauer")) {
    dispatch(srtbrandName("EddieBauer"));
    dispatch(
      getSrtInitialList(
        srtDataForApi?.brand ? srtDataForApi?.brand : "EddieBauer",
        srtDataForApi?.style ? srtDataForApi?.style : "",
        srtDataForApi?.factory ? srtDataForApi?.factory : "",
        srtDataForApi?.rejected_date ? srtDataForApi?.rejected_date : "",
        0,
        srtDataForApi?.season ? srtDataForApi?.season : ""
      )
    );
    dispatch(resetSrtData([]));
    dispatch(resetMassFilter());
    dispatch(resetSeasonFilter());
    dispatch(resetSrtSeasonFilter());
    dispatch(resetMassUnsolicitedFilter([]));
  } else if (path.includes("/sample-receipt-tracker/pd")) {
    dispatch(srtbrandName("PD"));
    dispatch(
      getSrtInitialList(
        srtDataForApi?.brand ? srtDataForApi?.brand : "PD",
        srtDataForApi?.style ? srtDataForApi?.style : "",
        srtDataForApi?.factory ? srtDataForApi?.factory : "",
        srtDataForApi?.rejected_date ? srtDataForApi?.rejected_date : "",
        0,
        srtDataForApi?.season ? srtDataForApi?.season : ""
      )
    );
    dispatch(resetSrtData([]));
    dispatch(resetMassFilter());
    dispatch(resetSeasonFilter());
    dispatch(resetSrtSeasonFilter());
    dispatch(resetMassUnsolicitedFilter([]));
  } else if (path.includes("/sample-receipt-tracker/sales-samples")) {
    dispatch(srtbrandName(""));
    dispatch(getSrtInitialList("", "", "", "", 1, ""));
    dispatch(resetSrtData([]));
    dispatch(resetMassFilter());
    dispatch(resetSeasonFilter());
    dispatch(resetSrtSeasonFilter());
    dispatch(resetMassUnsolicitedFilter([]));
  } else if (path.includes("/sample-receipt-tracker/unsolicited-samples")) {
    dispatch(srtbrandName(""));
    dispatch(
      getSrtUnsolicitedSamplesList(
        srtDataForApi?.brand ? srtDataForApi?.brand : "",
        srtDataForApi?.style ? srtDataForApi?.style : "",
        srtDataForApi?.factory ? srtDataForApi?.factory : "",
        srtDataForApi?.rejected_date ? srtDataForApi?.rejected_date : "",
        0,
        srtDataForApi?.season ? srtDataForApi?.season : ""
      )
    );
    dispatch(resetSrtData([]));
    dispatch(resetMassFilter());
    dispatch(resetSeasonFilter());
    dispatch(resetSrtSeasonFilter());
    dispatch(resetMassUnsolicitedFilter([]));
  } else {
    dispatch(resetSrtData([]));
    dispatch(resetMassFilter());
    dispatch(resetSeasonFilter());
    dispatch(resetSrtSeasonFilter());
    dispatch(resetMassUnsolicitedFilter([]));
    dispatch(srtbrandName("Dickies"));
  }

  useEffect(() => {
    localStorage.setItem("activeKey", "3");
    if (
      srtDataForApi?.brand == "DICKI" ||
      srtDataForApi?.brand == "DICKC" ||
      srtDataForApi?.brand == "DICKW" ||
      srtDataForApi?.brand == "DICKG"
    ) {
      localStorage.setItem("srtActiveKey", "1");
      // navigate("/sample-receipt-tracker/dickies");
    } else if (srtDataForApi?.brand == "SAUC") {
      localStorage.setItem("srtActiveKey", "2");
      // navigate("/sample-receipt-tracker/saucony");
    } else if (srtDataForApi?.brand == "EDBR") {
      localStorage.setItem("srtActiveKey", "3");
      // navigate("/sample-receipt-tracker/eddie-bauer");
    } else if (srtDataForApi?.brand == "PD") {
      localStorage.setItem("srtActiveKey", "4");
      // navigate("/sample-receipt-tracker/pd");
    } else if (srtDataForApi?.sale_sample == 1) {
      localStorage.setItem("srtActiveKey", "5");
      // navigate("/sample-receipt-tracker/sales-samples");
    } else if (srtDataForApi?.brand == "" && srtDataForApi?.sale_sample == 0) {
      localStorage.setItem("srtActiveKey", "6");
      // navigate("/sample-receipt-tracker/unsolicited-samples");
    } else {
      localStorage.setItem("srtActiveKey", "1");
    }
  }, [srtDataForApi]);

  const onKeyChange = (key) => {
    if (key == 1) {
      localStorage.setItem(
        "srtData",
        JSON.stringify({
          brand: "Dickies",
          style: "",
          factory: "",
          rejected_date: "",
          sale_sample: 0,
          season: "",
        })
      );
      localStorage.removeItem("srtMassData");
      localStorage.removeItem("currentPage");
      navigate("/sample-receipt-tracker/dickies");
      console.log("Brand");
      localStorage.setItem("srtActiveKey", "1");
      dispatch(srtbrandName("Dickies"));
      dispatch(srtEXportArray([]));
      dispatch(selectUnselectSrtTableRows([]));
      dispatch(resetSrtData([]));
      dispatch(resetMassFilter());
      dispatch(resetSeasonFilter());
      dispatch(resetSrtSeasonFilter());
      dispatch(resetMassUnsolicitedFilter([]));
    } else if (key == 2) {
      localStorage.removeItem("srtMassData");
      localStorage.removeItem("currentPage");
      navigate("/sample-receipt-tracker/saucony");
      localStorage.setItem(
        "srtData",
        JSON.stringify({
          brand: "Saucony",
          style: "",
          factory: "",
          rejected_date: "",
          sale_sample: 0,
          season: "",
        })
      );
      localStorage.setItem("srtActiveKey", "2");
      dispatch(srtbrandName("Saucony"));
      dispatch(srtEXportArray([]));
      dispatch(selectUnselectSrtTableRows([]));
      dispatch(resetSrtData([]));
      dispatch(resetMassFilter());
      dispatch(resetSeasonFilter());
      dispatch(resetSrtSeasonFilter());
      dispatch(resetMassUnsolicitedFilter([]));
    } else if (key == 3) {
      localStorage.removeItem("srtMassData");
      localStorage.removeItem("currentPage");
      navigate("/sample-receipt-tracker/eddie-bauer");
      localStorage.setItem(
        "srtData",
        JSON.stringify({
          brand: "EddieBauer",
          style: "",
          factory: "",
          rejected_date: "",
          sale_sample: 0,
          season: "",
        })
      );
      localStorage.setItem("srtActiveKey", "3");
      dispatch(srtbrandName("EddieBauer"));
      dispatch(srtEXportArray([]));
      dispatch(selectUnselectSrtTableRows([]));
      dispatch(resetSrtData([]));
      dispatch(resetMassFilter());
      dispatch(resetSeasonFilter());
      dispatch(resetSrtSeasonFilter());
      dispatch(resetMassUnsolicitedFilter([]));
    } else if (key == 4) {
      localStorage.removeItem("srtMassData");
      localStorage.removeItem("currentPage");
      navigate("/sample-receipt-tracker/pd");
      localStorage.setItem(
        "srtData",
        JSON.stringify({
          brand: "PD",
          style: "",
          factory: "",
          rejected_date: "",
          sale_sample: 0,
          season: "",
        })
      );
      localStorage.setItem("srtActiveKey", "4");
      dispatch(srtbrandName("PD"));
      dispatch(srtEXportArray([]));
      dispatch(selectUnselectSrtTableRows([]));
      dispatch(resetSrtData([]));
      dispatch(resetMassFilter());
      dispatch(resetSeasonFilter());
      dispatch(resetSrtSeasonFilter());
      dispatch(resetMassUnsolicitedFilter([]));
    } else if (key == 5) {
      localStorage.removeItem("srtMassData");
      localStorage.removeItem("currentPage");
      navigate("/sample-receipt-tracker/sales-samples");
      localStorage.setItem(
        "srtData",
        JSON.stringify({
          brand: "",
          style: "",
          factory: "",
          rejected_date: "",
          sale_sample: 1,
          season: "",
        })
      );
      localStorage.setItem("srtActiveKey", "5");
      dispatch(srtbrandName(""));
      dispatch(srtEXportArray([]));
      dispatch(selectUnselectSrtTableRows([]));
      dispatch(resetSrtData([]));
      dispatch(resetMassFilter());
      dispatch(resetSeasonFilter());
      dispatch(resetSrtSeasonFilter());
      dispatch(resetMassUnsolicitedFilter([]));
    } else if (key == 6) {
      localStorage.removeItem("srtMassData");
      localStorage.removeItem("currentPage");
      navigate("/sample-receipt-tracker/unsolicited-samples");
      localStorage.setItem(
        "srtData",
        JSON.stringify({
          brand: "",
          style: "",
          factory: "",
          rejected_date: "",
          sale_sample: 0,
          season: "",
        })
      );
      localStorage.setItem("srtActiveKey", "6");
      dispatch(srtbrandName(""));
      dispatch(srtEXportArray([]));
      dispatch(selectUnselectSrtTableRows([]));
      dispatch(resetSrtData([]));
      dispatch(resetMassFilter());
      dispatch(resetSeasonFilter());
      dispatch(resetSrtSeasonFilter());
      dispatch(resetMassUnsolicitedFilter([]));
    }
  };

  const activeKey = localStorage.getItem("srtActiveKey");

  return (
    <div className="srt-tabs">
      <Tabs activeKey={activeKey ? activeKey : "1"} onChange={onKeyChange}>
        <TabPane
          className="tab-one"
          tab={<div className="tab-one-background">DICKIES</div>}
          key={"1"}
        >
          <Dickies />
        </TabPane>
        <TabPane
          tab={<div className="tab-two-background">SAUCONY</div>}
          key={"2"}
        >
          <Saucony />
        </TabPane>
        <TabPane
          tab={<div className="tab-three-background">EDDIE BAUER</div>}
          key={"3"}
        >
          <Eddie />
        </TabPane>
        <TabPane
          tab={<div className="tab-four-background">PD & REDEVELOPMENT</div>}
          key={"4"}
        >
          <PdRedevelopment />
        </TabPane>
        <TabPane
          tab={<div className="tab-five-background">SALES SAMPLES</div>}
          key={"5"}
        >
          <SalesSamples />
        </TabPane>
        <TabPane
          tab={<div className="tab-six-background">UNSOLICITED SAMPLES</div>}
          key={"6"}
        >
          <UnsolicitedSamples />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default SrtTabs;
